<form [formGroup]="form" *ngIf="form">
  <div class="p-fluid">
    <!--Sifra prostora-->
    <kodit-field fieldLabel="Šifra prostora">
      <kodit-text-input-field
        [formCtrl]="form.controls.sifraProstora!"
        formCtrlId="sifraProstora"
      ></kodit-text-input-field>
    </kodit-field>
    <!--Grejna povrsina-->
    <kodit-field fieldLabel="Grejna površina">
      <kodit-number-input
        [formCtrl]="form.controls.grejnaPovrsina!"
        [maxFractionDigits]="2"
      ></kodit-number-input>
    </kodit-field>
    <!--Broj clanova-->
    <kodit-field fieldLabel="Broj članova">
      <kodit-number-input
        [formCtrl]="form.controls.brojClanova!"
        [ctrlOnlyIntegers]="true"
        [ctrlMinValue]="1"
      ></kodit-number-input>
    </kodit-field>
    <!--Broj clanova-->
    <kodit-field fieldLabel="Broj stana">
      <kodit-number-input
        [formCtrl]="form.controls.brojStana!"
        [ctrlOnlyIntegers]="true"
        [ctrlMinValue]="1"
      ></kodit-number-input>
    </kodit-field>
  </div>
</form>

<div class="loading-card" *ngIf="!form">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>

