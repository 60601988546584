<form [formGroup]="form" *ngIf="form">
  <div class="p-fluid">
    <p-messages severity="info" *ngIf="!shouldDisableInputs">
      <ng-template pTemplate>
        <div class="p-d-flex p-jc-between" style="width: 100%">
          <div class="p-d-flex p-flex-column p-jc-center gap-6">
            <div>
              Molimo Vas da prvo potvrdite podatke kako biste izabrali organizaciju
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-jc-center">
            <span
              class="p-message-icon pi pi-info-circle"
              style="font-size: 2.5rem"
            ></span>
          </div>
        </div>
      </ng-template>
    </p-messages>
    <!--Client ID-->
    <kodit-text-input-field
      [formCtrl]="form.controls.clientId!"
      formCtrlId="clientId"
      formCtrlLabel="Client ID"
      [isDisabled]="shouldDisableInputs"
    ></kodit-text-input-field>
    <!-- Client secret-->
    <kodit-text-input-field
      [formCtrl]="form.controls.clientSecret!"
      formCtrlId="clientSecret"
      formCtrlLabel="Client secret"
      [isDisabled]="shouldDisableInputs"
    ></kodit-text-input-field>
    <!-- Username-->
    <kodit-text-input-field
      [formCtrl]="form.controls.username!"
      formCtrlId="username"
      formCtrlLabel="Username"
      [isDisabled]="shouldDisableInputs"
    ></kodit-text-input-field>
    <!-- Password-->
    <kodit-text-input-field
      [formCtrl]="form.controls.password!"
      formCtrlId="password"
      formCtrlLabel="Password"
      [isDisabled]="shouldDisableInputs"
    ></kodit-text-input-field>

    <div *ngIf="shouldDisableInputs">
      <kodit-organisation-dropdown (onItemChange)="organisationChanged($event)"></kodit-organisation-dropdown>
    </div>
  </div>
</form>

<div class="loading-card" *ngIf="!form">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>

