import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  CreateMinimaxAuthCommand,
  ICreateMinimaxAuthCommand,
  IMinimaxApiInfoDto,
  MinimaxClient, SetMinimaxOrganisationCommand
} from '@kodit/core/data-api';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-minimax-api-form',
  templateUrl: './minimax-api-form.component.html',
  styleUrls: ['./minimax-api-form.component.scss'],
})
export class MinimaxApiFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<ICreateMinimaxAuthCommand>;
  shouldDisableInputs: boolean = false;
  apiInfoDto: IMinimaxApiInfoDto | undefined = undefined;
  private organisation: SelectItem | undefined = undefined;

  constructor(
    private _fb: FormBuilderTypeSafe,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _formHelper: FormHelper,
    private _sharedService: SharedService,
    private _client: MinimaxClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.apiInfoDto = this._dialogConfig.data.apiInfoDto;
    this.shouldDisableInputs = this.apiInfoDto.isInitialized;
    this.form = this._getForm(this.apiInfoDto);

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  organisationChanged(value: SelectItem)
  {
    this.organisation = value;
  }

  private _save() {
    if (!this.shouldDisableInputs) {
      this._initializeMinimax();
      return;
    }
    this._setOrganisation();
  }

  private _initializeMinimax() {
    if (!this.form!.valid) {
      this._formHelper.invalidateForm(this.form!);
      return;
    }

    this._sharedService.displayLoadingScreen(true, 'Kreiranje stana...');
    this._subs.add(
      this._client
        .initMinimax(this.form.value as CreateMinimaxAuthCommand)
        .subscribe((res) => {
          if (res.succeeded) {
            // this._alertService.addSuccessMsg("Minimax API uspešno inicijalizovan");
            this.shouldDisableInputs = true;
            this.apiInfoDto = res.data;
          }
        })
    );
  }

  private _setOrganisation() {
    if (!this.organisation) {
      this._alertService.addWarnMsg("Niste izabrali organizaciju");
      return;
    }

    this._sharedService.displayLoadingScreen(true, 'Ažuriranje organizacije...');
    this._subs.add(
      this._client
        .setOrganisation(new SetMinimaxOrganisationCommand({id: this.organisation.value, name: this.organisation.title}))
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg("Minimax API uspešno inicijalizovan");
            this._dialogRef.close(true);
          }
        })
    );
  }

  private _getForm(
    model?: IMinimaxApiInfoDto
  ): FormGroupTypeSafe<ICreateMinimaxAuthCommand> {
    return this._fb.group<ICreateMinimaxAuthCommand>({
      clientId: new FormControl(model?.clientId, {
        validators: [Validators.required],
      }),
      clientSecret: new FormControl(model?.clientSecret, {
        validators: [Validators.required],
      }),
      username: new FormControl(model?.username, {
        validators: [Validators.required],
      }),
      password: new FormControl(model?.password, {
        validators: [Validators.required],
      }),
    });
  }

  ngOnDestroy() {
    this.form = undefined;
    this._subs.unsubscribe();
  }
}
