import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  DelovodniDokumentDto,
  ICreateDelovodnikCommand,
  ICreatePraviloZaDelovodniDokumentCommand, IDelovodniDokumentDto,
  IStrankaRacunaDto,
  IUpdateDelovodnikCommand,
  StrankaRacunaDto
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class DelovodnikService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  //#region DelovodniDokumentiPraviloForm
  getDelovodniDokumentPraviloForm(
    model?: ICreatePraviloZaDelovodniDokumentCommand
  ): DelovodniDokumentPraviloForm {
    return this._fb.group<ICreatePraviloZaDelovodniDokumentCommand>({
      id: new FormControl(model ? model?.id : undefined),
      naziv: new FormControl(model ? model.naziv : '', {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      prefiks: new FormControl(model ? model.prefiks : '', {
        validators: [Validators.required, Validators.maxLength(9)],
      }),
      brojac: new FormControl(model ? model.brojac : 0, {
        validators: [Validators.required, Validators.min(1)],
      }),
    });
  }

  //#endregion DelovodniDokumentiPraviloForm

  //#region DelovodnikForm
  getCreateDelovodnikForm(): CreateDelovodnikForm {
    return this._fb.group<ICreateDelovodnikCommand>({
      naziv: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      praviloZaBrojId: new FormControl(undefined, {
        validators: [Validators.required],
      }),
      kategorijaId: new FormControl(undefined, {}),
    });
  }

  getUpdateDelovodnikForm(
    model?: IUpdateDelovodnikCommand
  ): UpdateDelovodnikForm {
    return this._fb.group<IUpdateDelovodnikCommand>({
      id: new FormControl(model.id, {
        validators: [Validators.required],
      }),
      naziv: new FormControl(model.naziv, {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      praviloZaBrojId: new FormControl(model.praviloZaBrojId, {
        validators: [Validators.required],
      }),
      kategorijaId: new FormControl(model.kategorijaId, {}),
    });
  }

  //#endregion Delovodnik

  //#region DelovodniDokument
  getDelovodniDokumentForm(
    delovodnikId: number,
    kategorijaId?: number,
    model?: DelovodniDokumentDto
  ): DelovodniDokumentForm {
    return this._fb.group<IDelovodniDokumentDto>({
      delovodniDokumentId:new FormControl(model?.delovodniDokumentId),
      delovodnikId: new FormControl(delovodnikId, {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      podbroj: new FormControl(model?.podbroj, {
        validators: [Validators.required],
      }),
      kategorijaId: new FormControl(kategorijaId, {
        validators: [Validators.required],
      }),
      datumNastanka: new FormControl(model?.datumNastanka, {
        validators: [Validators.required],
      }),
      datumUnosa: new FormControl(model?.datumUnosa ?? new Date(), {
        validators: [Validators.required],
      }),
      strankaRacunaDto: this.getStrankaFormGroup(
        model?.strankaRacunaDto ?? undefined
      ),
    });
  }

  private getStrankaFormGroup(
    model?: StrankaRacunaDto
  ): FormGroupTypeSafe<IStrankaRacunaDto> {
    return this._fb.group<IStrankaRacunaDto>({
      id: new FormControl(model?.id),
      pib: new FormControl(model?.pib),
      mb: new FormControl(model?.mb),
      naziv: new FormControl(model?.naziv),
    });
  }

  //#endregion DelovodniDokument
}

export declare type DelovodniDokumentPraviloForm = FormGroupTypeSafe<ICreatePraviloZaDelovodniDokumentCommand>;
export declare type CreateDelovodnikForm = FormGroupTypeSafe<ICreateDelovodnikCommand>;
export declare type UpdateDelovodnikForm = FormGroupTypeSafe<IUpdateDelovodnikCommand>;
export declare type DelovodniDokumentForm = FormGroupTypeSafe<IDelovodniDokumentDto>;

export interface ICreateDelovodniDokument {
  delovodnikId: number | undefined;
  podbroj: string | null;
  datumNastanka: Date | undefined;
  datumUnosa: Date | undefined;
  kategorijaId: number | undefined;
  posaljilacId?: number | null;
  strankaRacunaDto?: StrankaRacunaDto | null;
}
