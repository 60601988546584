import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import {
  GetDokumentForPdvAutocompleteQuery,
  IDokumentForPdvDto,
  IVezaniRacunDto,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijeClient,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IRequest } from 'libs/core/moduli/racun/src/lib/ui/stranka-racuna/stranka-racuna-autocomplete/stranka-racuna-autocomplete.component';
import { Subscription } from 'rxjs';
import { PdvEvidencijaPojedinacnaService } from '../../pojedinacna/pdv-evidencija-pojedinacna.service';
import { PdvEvidencijaPojedinacnaForm39Service } from '../../pojedinacna/pdv-evidencija-pojedinacna39-form.service';

@Component({
  selector: 'kodit-povezana-faktura-for-pdv-autocomplete',
  templateUrl: './povezana-faktura-for-pdv-autocomplete.component.html',
  styleUrls: ['./povezana-faktura-for-pdv-autocomplete.component.scss'],
})
export class PovezanaFakturaForPdvAutocompleteComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  dokumentSuggestions: IDokumentForPdvDto[];
  dokumentSuggestionsStr: string[];
  form!: FormGroupTypeSafe<IRequest>;
  private isFirstLoad: boolean = true;

  /** I/O */
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() dokumentCtrl!: AbstractControl;
  @Input() shouldDisable: boolean = false;

  tipRacuna: TipRacuna = TipRacuna.IZLAZNA_FAKTURA;
  vrstaRacuna: VrstaRacuna = VrstaRacuna.IZLAZNI;

  constructor(
    private _client: PdvEvidencijeClient,
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaPojedinacnaService,
    private _service39: PdvEvidencijaPojedinacnaForm39Service
  ) {
    this._subs = new Subscription();
    // this.dokumentCtrl = undefined!;
    this.dokumentSuggestions = [];
    this.dokumentSuggestionsStr = [];
    this.formCtrlId = 'dokument-for-pdv-autocomplete';
    this.parentCtrl = 'body';
  }

  ngOnInit() {
    this.form = this._fb.group<IRequest>({
      searchValue: new FormControl(null, {
        validators: Validators.required,
      }),
    });
    this.form.controls.searchValue.patchValue(this.dokumentCtrl.value.broj);
    this._setValidators();

    this._subs.add(
      this._service.getFormSaveClickedObject.subscribe((res) => {
        if (res) {
          if (this.form.invalid) {
            this.form.markAllAsTouched();
          }
        }
      })
    );

    this._subs.add(
      this._service.getTipDokumentaSubjectObservable.subscribe(
        (tipDokumentaSubject) => {
          if (tipDokumentaSubject == PdvEvidencijaTipDokumenta.FAKTURA) {
            this.tipRacuna = TipRacuna.IZLAZNA_FAKTURA;
          } else if (
            tipDokumentaSubject ==
            PdvEvidencijaTipDokumenta.DOKUMENT_O_POVECANJU
          ) {
            this.tipRacuna = TipRacuna.DOKUMENT_O_POVECANJU;
          } else if (
            tipDokumentaSubject ==
            PdvEvidencijaTipDokumenta.DOKUMENT_O_SMANJENJU
          ) {
            this.tipRacuna = TipRacuna.DOKUMENT_O_SMANJENJU;
          } else {
            this.tipRacuna = TipRacuna.AVANSNI_RACUN;
          }
        }
      )
    );

    this._subs.add(
      this._service.getIsIsporukaObservable.subscribe({
        next: () => {
          if (!this.isFirstLoad) {
            this.form.controls.searchValue.patchValue(null);
          }
        },
      })
    );

    this._subs.add(
      this._service.getTipDokumentaSubjectObservable.subscribe({
        next: () => {
          if (!this.isFirstLoad) {
            this.form.controls.searchValue.patchValue(null);
          }
        },
      })
    );
  }

  ngAfterViewInit(): void {
    this.isFirstLoad = false;
  }

  handleSearch(event: any) {
    if (this.form.value.searchValue.length < 1) {
      this.dokumentSuggestions = [];
      return;
    }
    const query = new GetDokumentForPdvAutocompleteQuery({
      broj: this.form.value.searchValue,
      tipRacuna: this.tipRacuna,
      vrstaRacuna: this._service.getIsIsporuka
        ? VrstaRacuna.IZLAZNI
        : VrstaRacuna.ULAZNI,
      jeStranaFaktura:
        this._service.getTipDokumentaSubject ==
        PdvEvidencijaTipDokumenta.INTERNI_RACUN_ZA_PROMET_STRANOG_LICA,
    });

    this._subs.add(
      this._client.getDokumentForPdvAutocomplete(query).subscribe((res) => {
        this.dokumentSuggestions = res.data!;
        this.dokumentSuggestionsStr = res.data!.map((x) => x.broj!);
      })
    );
  }

  handleSelectChange(dokumentStr: string) {
    const povezanaFaktura = this.dokumentSuggestions.filter(
      (x) => x.broj === dokumentStr
    )[0];
    this._service.setDokument = povezanaFaktura;
    this._service.setStranka = povezanaFaktura.stranka;
    //dok se ne odradi automatizacija, necemo nista popunjavati
    // this._service.setUkupanIznosNaknade = povezanaFaktura.ukupanIznos;
    // this._service.setOpisZa10 = povezanaFaktura.opisZa10;
    // this._service.setOpisZa20 = povezanaFaktura.opisZa20;
    // this._service.setOsnovica10 = povezanaFaktura.osnovica10 ?? 0;
    // this._service.setOsnovica20 = povezanaFaktura.osnovica20 ?? 0;
    // this._service.setObracunatPdv = povezanaFaktura.obracunatPdv ?? 0;
    this._service.setVremenskiPeriodPocetak = povezanaFaktura.datumPocetka;
    this._service.setVremenskiPeriodZavrsetak = povezanaFaktura.datumZavrsetka;
    this._service.setGodina = povezanaFaktura.datumPrometa?.getFullYear();
    this._service.setPdvPeriod = povezanaFaktura.datumPrometa?.getMonth();
    this._service.setDatumAvansa = povezanaFaktura.tip == TipRacuna.AVANSNI_RACUN ? povezanaFaktura.datumAvansa : null;
    if(povezanaFaktura.tip == TipRacuna.IZLAZNA_FAKTURA){
      if (povezanaFaktura.vezaniRacuni && povezanaFaktura.vezaniRacuni.length > 0) {
        const prviVezaniRacun = povezanaFaktura.vezaniRacuni[0];
        if (prviVezaniRacun.tip == TipRacuna.AVANSNI_RACUN) {
          this._service.setDisabledTipPovezanog = true;
          this._service.setPovezaneAvansneFakture = povezanaFaktura.vezaniRacuni ?? [];
        }
      }else{
        this._service.setDisabledTipPovezanog = false;
      }
    }
    if(povezanaFaktura.tip == TipRacuna.DOKUMENT_O_SMANJENJU){
      if (povezanaFaktura.vezaniRacuni && povezanaFaktura.vezaniRacuni.length > 0) {
        const prviVezaniRacun = povezanaFaktura.vezaniRacuni[0];
        if (prviVezaniRacun.tip == TipRacuna.AVANSNI_RACUN) {
          this._service.setDisabledTipPovezanog = true;
          this._service.setPovezaneAvansneFakture = povezanaFaktura.vezaniRacuni ?? [];
        }else if(prviVezaniRacun.tip == TipRacuna.IZLAZNA_FAKTURA){
          this._service.setDisabledTipPovezanog = true;
          this._service.setPovezaneIzlazneFakture = povezanaFaktura.vezaniRacuni ?? [];
        }
      }
      else if(povezanaFaktura.datumPocetka != null || povezanaFaktura.datumZavrsetka != null){
        this._service.setTipPovezanogVremenski();
      }
      else{
        this._service.setDisabledTipPovezanog = false;
      }
    }
    if(povezanaFaktura.tip == TipRacuna.DOKUMENT_O_POVECANJU){
      if (povezanaFaktura.vezaniRacuni && povezanaFaktura.vezaniRacuni.length > 0) {
        const prviVezaniRacun = povezanaFaktura.vezaniRacuni[0];
        if (prviVezaniRacun.tip == TipRacuna.IZLAZNA_FAKTURA) {
          this._service.setDisabledTipPovezanog = true;
          this._service.setPovezaneIzlazneFakture = povezanaFaktura.vezaniRacuni ?? [];
        }
      }
      else if(povezanaFaktura.datumPocetka != null || povezanaFaktura.datumZavrsetka != null){
        this._service.setTipPovezanogVremenski();
      }else{
        this._service.setDisabledTipPovezanog = false;
      }
    }
    //this._service.changeTipPovezanog();
    //this._service.setDisabledTipPovezanog = false;
    this.dokumentCtrl.patchValue(povezanaFaktura);
    this._removeValidators();
  }

  handleClear() {
    this.dokumentCtrl.reset();
    //this._service.resetForm39();
    this._service.resetStranka();
    this._service39.resetOsnovniPodaci();
    this._service39.resetNonBnProps();
    this._service39.resetVezaneRacune();
    this._service.changeTipPovezanog();
    this._service.setDisabledTipPovezanog = false;
    this._service.setDokumentBrojChanged = "";
    this._setValidators();
  }

  private _setValidators() {
    this.dokumentCtrl.setValidators(Validators.required);
    this.dokumentCtrl.updateValueAndValidity();
  }

  private _removeValidators() {
    this.dokumentCtrl.clearValidators();
    this.dokumentCtrl.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
