<div [formGroup]="form" class="p-d-flex p-flex-column p-fluid filters">
  <div class="p-d-flex p-flex-wrap " style="justify-content: space-between;">
    <div style="display: flex; " class="gap-8">
      <!-- Datum -->
      <div class="flex-1" *ngIf="tipIzvestaja == tip.DNEVNI_IZVESTAJ">
        <kodit-field fieldLabel="Datum: " fieldLabelFor="datum">
          <kodit-calendar
            [formCtrl]="form.controls.datum!"
            formCtrlId="datum"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <!-- Datum  od -->
      <div
        class="flex-1"
        *ngIf="tipIzvestaja == tip.PERIODICNI_IZVESTAJ || tipIzvestaja == tip.PERIODICNI_KASIR || tipIzvestaja == tip.IZVESTAJ_PO_ARTIKLIMA"
      >
        <kodit-field fieldLabel="Datum od: " fieldLabelFor="datumOd">
          <kodit-calendar
            [formCtrl]="form.controls.datumOd!"
            formCtrlId="datumOd"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <!-- Datum izdavanja do -->
      <div
        class="flex-1"
        *ngIf="tipIzvestaja == tip.PERIODICNI_IZVESTAJ || tipIzvestaja == tip.PERIODICNI_KASIR || tipIzvestaja == tip.IZVESTAJ_PO_ARTIKLIMA"
      >
        <kodit-field fieldLabel="Datum do: " fieldLabelFor="datumDo">
          <kodit-calendar
            [formCtrl]="form.controls.datumDo!"
            formCtrlId="datumDo"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <!-- Kasir -->
      <div class="flex-1" *ngIf="tipIzvestaja == tip.PERIODICNI_KASIR">
        <kodit-field fieldLabel="Kasir: " fieldLabelFor="kasir">
          <kodit-korisnik-autocomplete
            (onKorisnikChange)="handleKorisnikChange($event)"
            formCtrlId="kasirId"
            (onKasirClear)="onClear()"
          ></kodit-korisnik-autocomplete>
        </kodit-field>
      </div>
    </div>
    <div style="display: flex; align-items: center">
      <kodit-button
        class="p-d-flex p-align-end"
        btnLabel="Primeni"
        btnIcon="fa-regular fa-magnifying-glass"
        (btnClicked)="primeniFilter()"
      ></kodit-button>
    </div>
  </div>
</div>
