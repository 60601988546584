<kodit-card-container *ngIf="apiInfoDto">
  <div card-title>Minimax API menadžment</div>

  <div card-right-side class="p-d-flex gap-6">
    <button
      *ngIf="apiInfoDto.isInitialized"
      icon="far fa-rotate"
      pButton
      type="button"
      label="Sinhronizuj podatke"
      class="p-button p-button-outlined"
      (click)="sync()"
    ></button>
    <button
      [icon]="!apiInfoDto.isInitialized ? 'far fa-plus' : 'fas fa-pencil-alt'"
      pButton
      type="button"
      [label]="!apiInfoDto.isInitialized? 'Unesi' : 'Izmeni'"
      class="p-button p-button-outlined"
      (click)="onOpenForm()"
    ></button>
  </div>

  <div *ngIf="apiInfoDto.isInitialized" class="p-d-flex p-flex-column gap-12" card-body>
    <kodit-card-body-text
      label="Client ID"
      [text]="apiInfoDto.clientId"
    ></kodit-card-body-text>

    <kodit-card-body-text
      label="Client Secret"
      [text]="apiInfoDto.clientSecret"
    ></kodit-card-body-text>

    <kodit-card-body-text
      label="Username"
      [text]="apiInfoDto.username"
    ></kodit-card-body-text>
    <kodit-card-body-text
      label="Password"
      [text]="apiInfoDto.password"
    ></kodit-card-body-text>
    <kodit-card-body-text *ngIf="apiInfoDto.organisationName"
                          label="Organizacija"
                          [text]="apiInfoDto.organisationName"
    ></kodit-card-body-text>
  </div>
  <div *ngIf="!apiInfoDto.isInitialized" card-body>
    API podaci nisu uneti.
  </div>
</kodit-card-container>
