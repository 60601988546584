import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { FileType, IRelevantniDokumentListItemDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RelevantniDokumentListItemFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetFormArray(listData: IRelevantniDokumentListItemDto[]): FormArray {
    if (!listData || listData.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    listData.forEach((x) => {
      result.push(this.GetFormGroup(x));
    });
    return result;
  }

  public GetFormGroup(
    model?: IRelevantniDokumentListItemDto
  ): FormGroupTypeSafe<IRelevantniDokumentListItemDto> {
    return this._fb.group<IRelevantniDokumentListItemDto>({
      file: new FormControl(model?.file),
      fileType: new FormControl(model?.fileType ?? FileType.APPLICATION_PDF),
      naziv: new FormControl(model?.naziv),
      tipRelevantnogDokumenta: new FormControl(model?.tipRelevantnogDokumenta),
    });
  }
}
