<p-inputMask
  id="jmbg"
  mask="999999999999?9"
  kodit-select-all-on-focus
  [formControl]="formCtrl"
  [placeholder]="ctrlPlaceholder"

></p-inputMask>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-jmbg [formCtrl]="formCtrl"></kodit-error-info-jmbg>
