<div
  class="p-d-flex p-flex-column p-mt-3"
  [formGroup]="kompenzacijaForm"
  #parent
>
  <!-- Tabela stavki dokumenta -->
  <p-table
    [value]="obavezeKompenzacije.controls"
    styleClass="p-datatable-striped p-datatable-gridlines"
    [(selection)]="selectedObaveze"
  >
    <!-- START HEADER -->
    <ng-template pTemplate="header">
      <!-- header columns -->
      <tr class="columns">
        <th style="width: 15vw">Broj računa</th>
        <th style="width: 7vw">Datum</th>
        <th style="width: 7vw">Datum valute</th>
        <th style="width: 7vw">Iznos</th>
        <th style="width: 7vw"></th>
      </tr>
    </ng-template>
    <!-- START BODY -->
    <ng-template pTemplate="body" let-obaveza let-rowIndex="rowIndex">
      <ng-container [formArrayName]="currentFormArrayName">
        <tr [formGroupName]="rowIndex">
          <!-- broj računa -->
          <td>
            <div class="inputFullWidth celija">
              <kodit-racun-kompenzacija-autocomplete
                [disabled]="!kompenzacijaForm.get('stranka').value.naziv"
                [vrstaRacunaZaPretragu]="vrstaRacunZaPretragu"
                [ctrlPlaceholder]="
                  obavezeKompenzacije.at(rowIndex).get('broj').value ?? 'Unesite broj računa'
                "
                [formCtrlId]="
                  jeMojaFirmaPoverilac
                    ? 'obaveze-prema-poveriocu'
                    : 'obaveze-prema-duzniku'
                "
                (onChange)="handleRacunKompenzacijaChange($event, rowIndex)"
                (onClear)="handleRacunKompenzacijaClear()"
                [nazivPravca]="currentFormArrayName"
                [rowIndex]="rowIndex"
              ></kodit-racun-kompenzacija-autocomplete>
            </div>
            <div class="p-d-flex p-flex-row p-mt-2 p-ai-center" *ngIf="obavezeKompenzacije.at(rowIndex).get('originalanBroj').value">
              <div class="originalan-broj">Originalan broj:</div>
              <div class="originalan-broj-value p-ml-2">{{obavezeKompenzacije.at(rowIndex).get('originalanBroj').value}}</div>
            </div>
            <small
              *ngIf="
                !obavezeKompenzacije.at(rowIndex).get('broj').valid &&
                obavezeKompenzacije.at(rowIndex).get('broj').touched
              "
              class="p-invalid"
              >Morate odabrati račun
            </small>
          </td>
          <!-- datum dospeća -->
          <td>
            {{ obavezeKompenzacije.at(rowIndex).get('datumPrometa').value }}
          </td>
          <!-- datum valute -->
          <td>
            {{ obavezeKompenzacije.at(rowIndex).get('datumValute').value }}
          </td>
          <!-- iznos -->
          <td>
            <p-inputNumber
              [formControl]="obavezeKompenzacije.at(rowIndex).get('iznos')" 
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              (ngModelChange)="obracunajRazliku()"
            ></p-inputNumber>
          </td>
          <td style="vertical-align: middle" class="p-text-center">
            <div class="celija">
              <button
                pButton
                pRipple
                type="button"
                icon="far fa-trash-alt"
                class="p-button-text p-button-danger"
                pTooltip="Ukloni obavezu sa kompenzacije"
                (click)="deleteObavezu(rowIndex)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>

  <div class="p-d-flex p-mt-3">
    <button
      pButton
      pRipple
      type="button"
      icon="fas fa-plus"
      class="p-button-outlined"
      (click)="addNewObaveza()"
      label="Nova obaveza"
      pTooltip="Dodaj novu obavezu na kompenzaciju"
    ></button>
  </div>
</div>
