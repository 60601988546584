import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeSrb from '@angular/common/locales/sr-Latn';
import localeSvExtra from '@angular/common/locales/extra/sv';

/** Modules */
import { AppRoutingModule } from './app-routing.module';
import { CoreCoreUiModule } from '@kodit/core/core-ui';
import { API_BASE_URL } from '@kodit/core/data-api';
import { environment } from '../environments/environment';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

/** Components */
import { AppComponent } from './app.component';
import { PaketDodatakMultiselectComponent } from './paket-dodatak-multiselect/paket-dodatak-multiselect.component';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import {
  clearStateMetaReducer,
  filtersReducer,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { API_BASE_URL_POS } from 'libs/core/data-api/src/lib/eKompanija-pos-api';
registerLocaleData(localeSrb, 'sr-Latn', localeSvExtra);

@NgModule({
  declarations: [AppComponent, PaketDodatakMultiselectComponent],
  imports: [
    BrowserModule,
     AppRoutingModule,
    CoreCoreUiModule,
    StoreModule.forRoot(
      { filters: filtersReducer },
      { metaReducers: [clearStateMetaReducer] }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    { provide: API_BASE_URL_POS, useValue: environment.apiBaseUrl },
    {
      provide: LOCALE_ID,
      useValue: 'sr-Latn',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'RSD' },
    { provide: META_REDUCERS, useValue: clearStateMetaReducer, multi: true },
    FormBuilderTypeSafe,
  ],
  bootstrap: [AppComponent],
  exports: [PaketDodatakMultiselectComponent],
})
export class AppModule {}
