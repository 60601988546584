<p-sidebar
  #PodesavanjaSidebar
  [(visible)]="showSidebar"
  position="right"
  [(showCloseIcon)]="showcloseIcon"
  class="p-sidebar-md"
  (onHide)="onHide()"
  (onShow)="onShow()"
>
  <div class="header p-d-flex p-jc-between">
    <span
      style="
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: rgb(46, 57, 75);
      "
    >
      Podešavanja
    </span>
    <a
      (click)="onHide()"
      class="p-d-flex p-ai-center p-jc-center close"
      tabindex="0"
    >
      <img src="assets/icons/x.svg" width="24" height="24" alt="user-image" />
    </a>
  </div>

  <p-scrollPanel
    class="layout-menu-container"
    [style]="{ width: '100%', height: 'calc(100vh - 130px)' }"
    ><div class="_body">
      <div (click)="redirectToLink('kompanija')">
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/company.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Kompanija</div>
              <div class="_subtitle">
                Podešavanje osnovnih podataka kompanije, tekućih računa,
                poslovnih jedinica.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('racun')"
        *ngIf="shouldDisplayRacunCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/document-text.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Račun</div>
              <div class="_subtitle">
                Podešavanje osnovnih podataka računa, deviznih računa,
                memoranduma i pravila za brojeve računa.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-mt-1" (click)="redirectToLink('korisnicki-nalozi')">
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/user-settings.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Korisnički nalozi</div>
              <div class="_subtitle">
                Podešavanje korisničkih naloga, koriskičkih grupa i njihovih
                ovlašćenja
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('liste')"
        *ngIf="shouldDisplayListeCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/archive.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Šifarnici</div>
              <div class="_subtitle">
                Pregled artikala, unos artikala, unos usluga, unos
                repromaterijala
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('oznake')"
        *ngIf="shouldDisplayOznakeCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/tag-settings.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Oznake</div>
              <div class="_subtitle">
                Pregled stabla oznaka (tagova), izmena oznaka, brisanje oznaka.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('pocetna-stanja')"
        *ngIf="shouldDisplayPocetnaStanjaCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/import.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Početna stanja i šifarnici</div>
              <div class="_subtitle">
                Podešavanje početnih stanja kupaca, dobavljača, artikala.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('fiskalna-kasa')"
        *ngIf="shouldDisplayFiskalnaCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/smartphon.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Fiskalna kasa</div>
              <div class="_subtitle">
                Podešavanje fiskalne kase, pregled sertifikata, pregled poreskih
                stopa
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-mt-1"
        (click)="redirectToLink('arhivska-knjiga')"
        *ngIf="shouldDisplayArhivskaCard"
      >
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/inbox-archive.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">Arhivska knjiga</div>
              <div class="_subtitle">
                Podešavanja arhivske knjige, fizičkih lokacija, skladišnih
                jedinica, pravila za delovodne brojeve.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-mt-1" (click)="redirectToLink('api-menadzment')">
        <div class="p-d-flex podesavanja-card-item">
          <div class="p-d-flex p-ai-center">
            <div class="_image">
              <img
                src="assets/icons/settings.svg"
                width="24"
                height="24"
                alt="user-image"
              />
            </div>

            <div class="p-d-flex p-jc-between _title-section p-ml-3">
              <div class="_title">API menadžment</div>
              <div class="_subtitle">
                Podešavanje API ključa za eKompaniju, podešavanja SEF API
                ključa.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-scrollPanel>

  <!-- <ng-container>
    <p-divider></p-divider>
    <div>asdasdasdsa</div>
    <p-divider></p-divider>
  </ng-container> -->
</p-sidebar>
