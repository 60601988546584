import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  IRacunFilterDto,
  IStatusRacunaDto,
  IStatusUplateRacunaDto,
  RacunFilterDto,
  RacuniClient,
  StatusRacuna,
  TipEntiteta,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {Subscription} from 'rxjs';
import {AdvancedFilter, DynamicDialogConfig, DynamicDialogService,} from '@kodit/core/shared-ui';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {RacunFilterFormService, StatusRacunaFormService,} from '@kodit/core/form-definitions';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {TableFilterFormComponent} from '../../table/table-filter-form/table-filter-form.component';
import {RacunFilterService} from './racun-filter.service';
import {FormArray} from '@angular/forms';
import {Store} from '@ngrx/store';
import {clearFilter, setFilter} from '../state/filters/filter.actions';

@Component({
  selector: 'kodit-racun-filter',
  templateUrl: './racun-filter.component.html',
  styleUrls: ['./racun-filter.component.scss'],
})
export class RacunFilterComponent
  implements
    OnInit,
    OnDestroy,
    OnDynamicDialogInit,
    AdvancedFilter<IRacunFilterDto> {
  /** Subs */
  private _saveTemplateSub: Subscription = new Subscription();
  private _templateChange: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();

  /** Props */
  excludeTipoveEntiteta: TipEntiteta[] = [];
  filterItems: RacunFilterDto[] = [];
  selectedFilter: RacunFilterDto;
  form: FormGroupTypeSafe<IRacunFilterDto>;
  filterData: IRacunFilterDto;
  statusiRacuna: StatusRacuna[] = []; //! ukoliko je [] vraca nam sve statuse sa back-a
  prikazFilteraZaUlazne: boolean = false;

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL_TINY
  );

  constructor(
    private _client: RacuniClient,
    private _racunFilterFS: RacunFilterFormService,
    private _racunFilterService: RacunFilterService,
    private _dialogService: DynamicDialogService,
    private _statusRacunaFormService: StatusRacunaFormService,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this._racunFilterFS.GetNaprednaPretragaRacunaFormGroup(this.filterData);
    this.prikazFilteraZaUlazne = this.filterData.vrstaRacuna == VrstaRacuna.ULAZNI;
  }

  onFilterSubmit() {
    this._storage.dispatch(setFilter({
      key: this._getFilterSearchKey(),
      filter: JSON.stringify(this.form.value) }));
  }

  onFilterHide(shouldReset: boolean) {
    if(shouldReset){
      this._storage.dispatch(
        clearFilter({
          key: this._getFilterSearchKey(),
        })
      );
    }

    this._racunFilterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset ? new RacunFilterDto() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(TableFilterFormComponent, config);
    this._dialogCloseSub = ref.onClose.subscribe((data: string) => {
      if (data) {
        //this.saveAsTemplate(data);
      }
    });
  }

  private saveAsTemplate(naziv: string) {
    const request = new RacunFilterDto(this.form.value);
    request.naziv = naziv;

    this._client.createFilterTemplate(request).subscribe((x) => {});

    this.form.patchValue(request);
  }

  selectedStatusiRacuna(selectedItems: any) {
    (this.form.controls.statusi as FormArray).clear();
    selectedItems.forEach((element) => {
      (this.form.controls.statusi as FormArray).push(
        this._statusRacunaFormService.GetStatusFormGroup({
          status: element,
        } as IStatusRacunaDto)
      );
    });
  }

  selectedStatusiUplateRacuna(selectedItems: any) {
    (this.form.controls.statusiUplateRacuna as FormArray).clear();
    selectedItems.forEach((element) => {
      (this.form.controls.statusiUplateRacuna as FormArray).push(
        this._statusRacunaFormService.GetStatusUplateFormGroup({
          status: element,
        } as IStatusUplateRacunaDto)
      );
    });
  }

  private _getFilterSearchKey() : string{
    return `${VrstaRacuna[this.filterData.vrstaRacuna]}|${TipRacuna[this.filterData.tip]}` +
      (this.filterData.tip === TipRacuna.EMPTY ? '|' + this.filterData.strankaId : '');
  }

  ngOnDestroy(): void {
    this._saveTemplateSub.unsubscribe();
    this._templateChange.unsubscribe();
    this._dialogCloseSub.unsubscribe();
  }
}
