import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  IMinimaxApiInfoDto,
  MinimaxClient
} from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import { MinimaxApiFormComponent } from '../minimax-api-form/minimax-api-form.component';

@Component({
  selector: 'kodit-minimax-api-card',
  templateUrl: './minimax-api-card.component.html',
  styleUrls: ['./minimax-api-card.component.scss']
})
export class MinimaxApiCardComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  apiInfoDto: IMinimaxApiInfoDto;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: MinimaxClient,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getApiInfo()
        .subscribe((result) => {
          this.apiInfoDto = result.data;
        })
    );
  }

  onOpenForm()
  {
    this.dialogConfig.data = { apiInfoDto: this.apiInfoDto};
    this.dialogConfig.header = 'Minimax API';
    this.dialogConfig.maximisable = false;
    const ref = this._dialogService.open(MinimaxApiFormComponent, this.dialogConfig);

    this._subs.add(
      ref.onClose.subscribe((value: IMinimaxApiInfoDto) => {
        if(value){
          this._load();
        }
      })
    );
  }

  sync(){
    this._sharedService.displayLoadingScreen(true, 'Sinhronizovanje podataka...');
    this._subs.add(
      this._client
        .syncItems()
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data);
          }
        })
    );
  }

}
