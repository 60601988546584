import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BezbednosniElementDto,
  FilterChipDto,
  FiskalniRacuniClient,
  GetBezbednosniElementiTableQuery,
  IGetBezbednosniElementiTableQuery,
  IPaginatedResultOfBezbednosniElementDto,
  PaginatedResultOfBezbednosniElementDto,
  TipBezbednosnogElementa,
} from '@kodit/core/data-api';
import {
  ActionClass,
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  TableConfig,
} from '@kodit/core/shared-ui';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { AlertService, ConfigService, SharedService } from '@kodit/core/services';
import { SertifikatFiskalnaUploadFormComponent } from '../sertifikat-fiskalna-upload-form/sertifikat-fiskalna-upload-form.component';
import { UpdateBezbednosniElementFormComponent } from './update-bezbednosni-element-form/update-bezbednosni-element-form.component';

@Component({
  selector: 'kodit-bezbednosni-elementi-table',
  templateUrl: './bezbednosni-elementi-table.component.html',
  styleUrls: ['./bezbednosni-elementi-table.component.scss'],
})
export class BezbednosniElementiTableComponent implements OnInit, OnDestroy {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  paginatedData: IPaginatedResultOfBezbednosniElementDto;
  tableConfig: TableConfig;
  chipItems: FilterChipDto[] = [];
  _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  isPreuzmiPoreskeGrupeDisabled = false;
  sertifikatiTable: BezbednosniElementDto[] = [];

  constructor(
    private _fiskalniClient: FiskalniRacuniClient,
    private _alertService: AlertService,
    private _configService: ConfigService,
    private _client: FiskalniRacuniClient,
    private _dialogService: DynamicDialogService,
    private _confirmationService: ConfirmationService,
     private _sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  private _load(request?: IGetBezbednosniElementiTableQuery) {
    if (!request) {
      request = {
        pageNumber: 0,
        pageSize: 10,
      };
    }

    this._subs.add(
      this._client
        .getBezbednosniElementiForTable(
          request as GetBezbednosniElementiTableQuery
        )
        .subscribe(
          (res: PaginatedResultOfBezbednosniElementDto) => {
            this.sertifikatiTable = res.data;
            this.paginatedData = res;
            this.chipItems = res.activeFilters;
            this._configService.setIsBusy = false;
          },
          (error) => {
            this._configService.setIsBusy = false;
          }
        )
    );
  }

  verifikujStatus() {
    this._subs.add(
      this._fiskalniClient
        .verifikujPodatkeBezbednosnogElementa()
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.messages[0]);
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Sertifikati',
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        this._skipFirst = event.first;
        this.numberOfRowsDisplayed = event.rows;
        this._load({
          pageNumber: this._skipFirst,
          pageSize: this.numberOfRowsDisplayed,
        } as GetBezbednosniElementiTableQuery);
      },
      columns: [
        {
          header: 'Naziv',
          field: 'naziv',
          type: 'text',
        },
        {
          header: 'JID',
          field: 'jid',
          type: 'text',
        },
        {
          header: 'Tip',
          field: 'tip',
          subField: 'tipStr',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.PRIMARY,
          label: 'Unos',
          icon: 'fas fa-plus',
          hasAccessTooltip: 'Unos sertifikata',
          noAccessTooltip: 'Nemate ovlašćenja za uvoz sertifikata',
          callback: () => {
            this.dialogConfig.header = 'Uvoz sertifikata';
            this.dialogConfig.customSubmitButton = { label: 'Uvezi' } as any;
            this.dialogConfig.customCancelButton = { label: 'Izadji' } as any;
            //this.dialogConfig.hideFooter = true;
            this.dialogConfig.maximisable = false;
            this._openDialog();
          },
        },
      ],
      rowActions: [
        /* Inicijalizuj sa novom poslovnom jedinicom */
        {
          type: ActionType.CREATE,
          icon: 'fa-solid fa-credit-card',
          hasAccessTooltip: 'Inicijalizuj sa novom poslovnom jedinicom',
          noAccessTooltip: 'Nemate ovlašćenja za inicijalizovanje kartice',
          shouldDisplayByCondition: (rowData: BezbednosniElementDto) =>
            rowData.tip.tip === TipBezbednosnogElementa.LPFR &&
            !rowData.jeInicijalizovan,
          callback: (index: number) => {
            this._subs.add(
              this._fiskalniClient
                .initBezbednosniElementBezPoslovneJedinice(
                  this._getPaginatedItem(index).id
                )
                .subscribe((res) => {
                  this._alertService.addSuccessMsg(res.messages[0]);
                  this._load();
                })
            );
          },
        },
        /* Inicijalizuj sa postojecom poslovnom jedinicom */
        {
          type: ActionType.CREATE,
          icon: 'fa-regular fa-credit-card',
          hasAccessTooltip:
            'Inicijalizuj/Ažuriraj karticu sa postojećom poslovnom jedinicom',
          noAccessTooltip: 'Nemate ovlašćenja za inicijalizovanje kartice',
          callback: (index: number) => {
            this.openUpdateDialog(index);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.DELETE,
          icon: 'fa-solid fa-trash',
          hasAccessTooltip: 'Obrišite sertifikat',
          noAccessTooltip: 'Nemate ovlašćenja za brisanje sertifikata',
          callback: (index: number) => {
            this._confirmationService.confirm({
              header: 'Potvrda brisanja',
              message: 'Da li želite da obrišete sertifikat?',
              acceptLabel: 'Obriši',
              rejectLabel: 'Odustani',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._deleteSertifikat(index);
              },
            });
          },
        },
      ],
    });
  }
  // toDo: implementirati otvaranje info dialoga svakog bezbednosnog elementa
  // private _openBezbednosniElementInfo(itemIndex: number) {
  //   var bezbednosniElement = this._getPaginatedItem(itemIndex);
    
  //   this.dialogConfig.header = bezbednosniElement.naziv;
  //   this.dialogConfig.hideFooter = true;
  //   this.dialogConfig.maximisable = false;
    
    
  //    this._dialogService.open(
  //      BezbednosniElementInfoDialogComponent,
  //      this.dialogConfig
  //    );
  // }

  private _openDialog(): void {
    const ref = this._dialogService.open(
      SertifikatFiskalnaUploadFormComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this._load();
        }
        this.dialogConfig.hideFooter = false;
      })
    );
  }

  private openUpdateDialog(index: number): void {
    let element = this._getPaginatedItem(index);
    
    this.dialogConfig.header = element.naziv;
    this.dialogConfig.data = {
      bezbednosniElementId: element.id,
      tipBezbednosnogElementa: element.tip.tip
    };
    
    const ref = this._dialogService.open(
      UpdateBezbednosniElementFormComponent,
      this.dialogConfig
    );
    this._subs.add(
      ref.onClose.subscribe(() => {
        this._load();
      })
    );
  }

  private _deleteSertifikat(index: number) {
    this._sharedService.displayLoadingScreen(true, 'Brisanje sertifikata...');
    this._subs.add(
      this._client
        .deleteSertifikat(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): BezbednosniElementDto {
    return this.sertifikatiTable[this._getPaginatedIndex(index)];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
