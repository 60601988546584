import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  IKorisnikAutocompleteDto,
  KorisniciClient,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-korisnik-autocomplete',
  templateUrl: './korisnik-autocomplete.component.html',
  styleUrls: ['./korisnik-autocomplete.component.scss'],
})
export class KorisnikAutocompleteComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  formGroup: FormGroupTypeSafe<IRequest>;
  korisnikSuggestions: IKorisnikAutocompleteDto[] = [];

  /** I/O */
  @Input() mustSelect: boolean = false;
  @Input() formCtrlId: string;
  @Output() onKorisnikChange = new EventEmitter<IKorisnikAutocompleteDto>();
  @Output() onNazivChange = new EventEmitter<string>();
  @Output() onKasirClear = new EventEmitter<void>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _alertService: AlertService,
    private _client: KorisniciClient
  ) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(''),
    });
  }

  handleSearch(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      return;
    }
    this._subs.add(
      this._client
        .getKorisnikAutocomplete(this.formGroup.value.searchValue)
        .subscribe(
          (result) => {
            this.korisnikSuggestions = [
              ...result.data.korisnikAutocompleteDtoList,
            ];
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  selectionChanged(value: IKorisnikAutocompleteDto) {
    this.onKorisnikChange.emit(value);
  }

  nazivChanged(event: any) {
    if (this.onNazivChange && event.originalTarget?.value) {
      this.onNazivChange.emit(event.originalTarget.value);
    }
  }

  onClear() {
    this.formGroup.reset();
    if (this.onKasirClear) {
      this.onKasirClear.emit();
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
