import { Component, OnInit } from '@angular/core';
import {
  CreateDelovodniDokumentCommand,
  DelovodniciClient,
  DelovodnikInfoDto,
  FileParameter,
  UpdateDelovodniDokumentCommand
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  DelovodniDokumentForm,
  DelovodnikService,
} from 'libs/core/moduli/podesavanje/src/lib/delovodnik/delovodnik-service';
import { Subscription } from 'rxjs';
import { result } from 'lodash';

@Component({
  selector: 'kodit-delovodni-dokument-form',
  templateUrl: './delovodni-dokument-form.component.html',
  styleUrls: ['./delovodni-dokument-form.component.scss'],
})
export class DelovodniDokumentFormComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();
  /** Forms */
  form!: DelovodniDokumentForm;

  /** Props */
  delovodnikDto: DelovodnikInfoDto | undefined;
  file: FileParameter | undefined;
  isEdit: boolean = false;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _delovodnikService: DelovodnikService,
    private _client: DelovodniciClient,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) {}

  onUpload(file: File) {
    this.file = { fileName: file.name, data: file };
  }

  ngOnInit(): void {
    this.delovodnikDto = this._dialogConfig.data.delovodnikDto;

    this._initForm(this._dialogConfig.data.id);

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if(this.isEdit)
    {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje delovodnog dokumenta...'
      );

      this._subs.add(
        this._client
          .updateDelovodniDokument(
            this.form.value as UpdateDelovodniDokumentCommand
          )
          .subscribe((result) => {
            if (result.succeeded) {
              this._alertService.addSuccessMsg(result.data!);
            } else {
              this._alertService.addWarnMsg(result.messages![0]);
            }
            this._dialogRef.close(result);
          })
      );
      return;
    }

    this._sharedService.displayLoadingScreen(
      true,
      'Kreiranje delovodnog dokumenta...'
    );

    this._subs.add(
      this._client
        .createDelovodniDokument(
          this.form.value as CreateDelovodniDokumentCommand
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.messages![0]);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
          this._dialogRef.close(result);
        })
    );
  }

  private _initForm(delovodniDokumentId?: number){
    if(delovodniDokumentId){
      this.isEdit = true;
      this._subs.add(
        this._client.getDelovodniDokument(delovodniDokumentId).subscribe((result) => {
          this.form = this._delovodnikService.getDelovodniDokumentForm(
            this.delovodnikDto?.id!,
            this.delovodnikDto?.kategorijaId,
            result.data
          );
        })
      );
      return;
    }

    this.form = this._delovodnikService.getDelovodniDokumentForm(
      this.delovodnikDto?.id!,
      this.delovodnikDto?.kategorijaId
    );
  }
}
