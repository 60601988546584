import { FormArray, FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IPredmetStavkeFilterDto,
  IRepromaterijalDto,
  IRobaDto,
  TipArtikla,
  TipPredmeta,
} from '@kodit/core/data-api';
import {
  PredmetStavkeFormGroupRequest,
  PredmetStavkeFormService,
} from './predmet-stavke-form.service';
import { OznakaFormService } from '../common/oznaka-form.service';
import {MagacinFormService} from "../kompanija/magacin-form.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { StopaPdv } from '@kodit/core/shared';

@Injectable({
  providedIn: 'root',
})
export class RobaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _predmetStavkeFS: PredmetStavkeFormService,
    private _oznakeFS: OznakaFormService,
    private _magacinFS : MagacinFormService
  ) {}

  /**
   * Konvertuje model za robu i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model robe od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */

  stopaPdv$ = new BehaviorSubject<StopaPdv>(null);

  public GetRobaFormGroup(model?: IRobaDto): FormGroupTypeSafe<IRobaDto> {
    return this._fb.group<IRobaDto>({
      predmetStavkeDto: this._predmetStavkeFS.GetPredmetStavkeFormGroup(
        new PredmetStavkeFormGroupRequest({
          model: model?.predmetStavkeDto,
          tipPredmeta: TipPredmeta.ROBA,
          tipArtikla: TipArtikla.ROBA,
          isSifraRequired: false,
        })
      ),
      kolicina: new FormControl(model?.kolicina),
      napomena: new FormControl(model?.napomena),
      gtin: new FormControl(model?.gtin),
      nazivNaEngleskom: new FormControl(model?.nazivNaEngleskom),
      netoTezinaKg: new FormControl(model?.netoTezinaKg ?? 0),
      tarifniBrojStr: new FormControl(model?.tarifniBrojStr),
      kategorija: this._oznakeFS.GetOznakaFormGroup(model?.kategorija),
      repromaterijali : this.GetRepromaterijaliFormArray(model?.repromaterijali),
      magacini : this._magacinFS.GetMagaciniFormArray(model?.magacini)
    });
  }

  public GetRobaFormArray(artikli? : IRobaDto[]) : FormArray{
    if(!artikli || artikli.length === 0){
      return this._fb.array([]);
    }

    const result : FormArray = this._fb.array([]);

    artikli.forEach((artikal)=>{
      result.push(this.GetRobaFormGroup(artikal));
    })

    return result;
  }

  public GetRobaTableRequest(
    model?: IPredmetStavkeFilterDto
  ): FormGroupTypeSafe<IPredmetStavkeFilterDto> {
    return this._fb.group<IPredmetStavkeFilterDto>({
      naziv: new FormControl(model?.naziv),
      sifra: new FormControl(model?.sifra),
    });
  }

   public GetRepromaterijaliFormArray(repromaterijali?: IRepromaterijalDto[]): FormArray {
    if (!repromaterijali || repromaterijali.length === 0) {
      return this._fb.array([]);
    }
    const result: FormArray = this._fb.array([]);

    repromaterijali.forEach((obaveza) => {
      result.push(this.GetRepromaterijaliFormGroup(obaveza));
    });

    return result;
  }

  public GetRepromaterijaliFormGroup(
    model?: IRepromaterijalDto
  ): FormGroupTypeSafe<IRepromaterijalDto> {
    return this._fb.group<IRepromaterijalDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv),
      tarifniBrojStr : new FormControl(model?.tarifniBrojStr)
    });
  }

   set setStopaPdv(model: StopaPdv) {
      this.stopaPdv$.next(model);
    }

    get getTipDokumentaSubject(): StopaPdv {
      return this.stopaPdv$.getValue();
    }

    get getTipDokumentaSubjectObservable(): Observable<StopaPdv> {
      return this.stopaPdv$.asObservable();
    }
}
