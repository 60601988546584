<kodit-info-container>
  <!-- Leva strana -->
  <ng-container body-2-of-3>
    <p-tabView
      (activeIndexChange)="handleIndexChange($event)"
      class="kodit-card-tab"
    >
      <p-tabPanel #kupacPanel *ngIf="!hasBesplatniPaket" header="Kupac"></p-tabPanel>
      <p-tabPanel #dobavljacPanel *ngIf="shouldDisplayDobavljac" header="Dobavljač"></p-tabPanel>
      <p-tabPanel #primljeniAvansPanel *ngIf="!hasBesplatniPaket && shouldDisplayPrimljeniAvans"
                  header="Primljeni avans"></p-tabPanel>
      <p-tabPanel #datiAvansPanel *ngIf="!hasBesplatniPaket" header="Dati avans"></p-tabPanel>
    </p-tabView>

    <div [ngSwitch]="activeIndex">
      <div *ngSwitchCase="0">
        <div class="p-mb-3">
          <kodit-racun-summary
            label1="Promet"
            label2="Uplate"
            label3="Ukupno PDV 10%"
            label4="Ukupno PDV 20%"
          ></kodit-racun-summary>
        </div>

        <div class="p-mb-3">
          <kodit-racun-van-valute-summary
            displayPodsetnikZaPlacanje="true"
          ></kodit-racun-van-valute-summary>
        </div>
        <kodit-racun-table [vrstaRacuna]="izlazniRacun"></kodit-racun-table>
      </div>

      <div *ngSwitchCase="1">
        <div class="p-mb-3">
          <kodit-racun-summary
            label1="Promet"
            label2="Isplate"
            label3="Ukupno PDV 10%"
            label4="Ukupno PDV 20%"
          ></kodit-racun-summary>
        </div>
        <div class="p-mb-3">
          <kodit-racun-van-valute-summary></kodit-racun-van-valute-summary>
        </div>
        <kodit-racun-table [vrstaRacuna]="ulazniRacun"></kodit-racun-table>
      </div>

      <div *ngSwitchCase="2">
        <div class="p-mb-4">
          <kodit-racun-summary
            [jeAvans]="true"
            label1="Uplate"
            label2="Iskorišćeno"
          ></kodit-racun-summary>
        </div>
        <kodit-racun-table
          [tipRacuna]="avansniRacun"
          [vrstaRacuna]="izlazniRacun"
        >
        </kodit-racun-table>
      </div>

      <div *ngSwitchCase="3">
        <div class="p-mb-4">
          <kodit-racun-summary
            [jeAvans]="true"
            label1="Isplate"
            label2="Iskorišćeno"
          ></kodit-racun-summary>
        </div>
        <kodit-racun-table
          [tipRacuna]="avansniRacun"
          [vrstaRacuna]="ulazniRacun"
        >
        </kodit-racun-table>
      </div>
    </div>
  </ng-container>

  <!-- Desna strana -->
  <ng-container body-1-of-3>
    <!-- Osnovne informacije -->
    <kodit-card-container
      [isTitleWithIcon]="true"
      [showDivider]="false"
      titleIcon="fas fa-building"
    >
      <div card-title-with-icon>
        {{ strankaInfoDto.naziv }}
      </div>

      <div card-subtitle-with-icon>
        <span
          *ngIf="strankaInfoDto.jeKupac"
          class="item-badge tip-pravnog-lica-kupac ng-star-inserted p-mr-2"
        >KUPAC</span
        >
        <span
          *ngIf="strankaInfoDto.jeDobavljac"
          class="item-badge tip-pravnog-lica-dobavljač ng-star-inserted"
        >DOBAVLJAČ</span
        >
      </div>
      <div card-body class="p-d-flex p-flex-column p-jc-between p-mt-3 stranka-description">
        <kodit-card-body-text
          [text]="strankaInfoDto.adresa"
          label="Adresa"
        ></kodit-card-body-text>

        <kodit-card-body-text
          *ngIf="strankaInfoDto.pib !== null && !strankaInfoDto.jeStranoLice"
          [text]="strankaInfoDto.pib"
          label="PIB"
        ></kodit-card-body-text>
        <kodit-card-body-text
          *ngIf="strankaInfoDto.pib !== null && !strankaInfoDto.jeStranoLice"
          [text]="strankaInfoDto.maticniBroj"
          label="Matični broj"
        ></kodit-card-body-text>

        <div *ngIf="strankaInfoDto.jeStranoLice">
          <kodit-card-body-text
            *ngIf="strankaInfoDto.pib !== null"
            [text]="strankaInfoDto.registracioniBroj"
            label="Registracioni broj"
          ></kodit-card-body-text>
        </div>
      </div>
    </kodit-card-container>

    <div *ngIf="innerWidth > 1366" class="_stranka_info_large_wp">
      <!-- Kontakti -->
      <div class="p-mt-3">
        <kodit-kontakt-list
          [strankaId]="strankaInfoDto.id"
        ></kodit-kontakt-list>
      </div>
      <!-- Cenovnici -->
      <div *ngIf="!authService.hasInfoZgrada()" class="p-mt-3">
        <kodit-cenovnik-list
          [strankaId]="strankaInfoDto.id"
        ></kodit-cenovnik-list>
      </div>
      <!-- Informacije o isporuci -->
      <div *ngIf="!authService.hasInfoZgrada()" class="p-mt-3">
        <kodit-informacija-isporuke-list
          [strankaId]="strankaInfoDto.id"
        ></kodit-informacija-isporuke-list>
      </div>
      <!-- Stanovi -->
      <div *ngIf="authService.hasInfoZgrada()" class="p-mt-3">
        <kodit-stan-list
          [strankaId]="strankaInfoDto.id"
        ></kodit-stan-list>
      </div>
    </div>

    <div *ngIf="innerWidth < 1367" class="_stranka_info_small_wp">
      <div class="p-mt-3">
        <p-panel [collapsed]="true" [toggleable]="true" header="Kontakt osobe">
          <kodit-kontakt-list
            [strankaId]="strankaInfoDto.id"
          ></kodit-kontakt-list>
        </p-panel>
      </div>

      <div *ngIf="!authService.hasInfoZgrada()" class="p-mt-3">
        <p-panel [collapsed]="true" [toggleable]="true" header="Cenovnici">
          <kodit-cenovnik-list
            [strankaId]="strankaInfoDto.id"
          ></kodit-cenovnik-list>
        </p-panel>
      </div>

      <div *ngIf="!authService.hasInfoZgrada()" class="p-mt-3">
        <p-panel [collapsed]="true" [toggleable]="true" header="Informacije o isporuci">
          <kodit-informacija-isporuke-list
            [strankaId]="strankaInfoDto.id"
          ></kodit-informacija-isporuke-list>
        </p-panel>
      </div>
      <!-- Stanovi -->
      <div *ngIf="authService.hasInfoZgrada()" class="p-mt-3">
        <p-panel [collapsed]="true" [toggleable]="true" header="Stanovi">
          <kodit-stan-list
            [strankaId]="strankaInfoDto.id"
          ></kodit-stan-list>
        </p-panel>
      </div>
    </div>
  </ng-container>
</kodit-info-container>
