import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { MinimaxClient } from '@kodit/core/data-api';
import { AbstractControl } from '@angular/forms';
import { isEmptyCheck } from '@kodit/core/shared';

@Component({
  selector: 'kodit-minimax-pravila-za-broj-dropdown',
  templateUrl: './minimax-pravila-za-broj-dropdown.component.html',
  styleUrls: ['./minimax-pravila-za-broj-dropdown.component.scss']
})
export class MinimaxPravilaZaBrojDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];
  @Input() formCtrl: AbstractControl;
  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _client: MinimaxClient) { }

  ngOnInit(): void {
    this._load();
  }

  handleItemChange(event: any){
    this.onItemChange.emit(event);
  }

  private _load() {
    this._subs.add(
      this._client.getDocumentNumberings()
        .subscribe(res => {
          if(res.succeeded){
            this.items = res.data.map((x) => ({
              value: x.id,
              label: x.naziv,
              title: x.naziv,
            }));
            if(isEmptyCheck(this.formCtrl.value)){
              this.formCtrl.patchValue(this.items[0].value);
            }
          }
        })
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
