<form [formGroup]="robaForm" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <div class="p-field">
      <label for="select-tip" kodit-form-label>Tip artikla:</label>
      <kodit-tip-artikla-dropdown
        [tipUplateCtrl]="robaForm.controls.predmetStavkeDto.get('tipArtikla')"
      ></kodit-tip-artikla-dropdown>
    </div>

    <div>
      <div class="p-field">
        <label for="naziv" kodit-form-label>Naziv *</label>
        <kodit-text-input
          formCtrlId="naziv"
          [formCtrl]="robaForm.controls.predmetStavkeDto.get('naziv')"
        ></kodit-text-input>
      </div>

      <div class="p-field">
        <label for="naziv" kodit-form-label>Naziv na engleskom</label>
        <kodit-text-input
          formCtrlId="naziv-na-engleskom"
          [formCtrl]="robaForm.controls.nazivNaEngleskom"
        ></kodit-text-input>
      </div>
    </div>

    <kodit-text-input-field
      *ngIf="mode === editModeValue"
      [isDisabled] = true
      formCtrlLabel="Label (Oznaka)"
      [formCtrl]="robaForm.controls.predmetStavkeDto.get('label')"
    ></kodit-text-input-field>

    <kodit-field
      fieldLabel="Jedinica mere"
      fieldLabelFor="jm"
      [isFieldRequired]="
        robaForm.controls.predmetStavkeDto.get('jedinicaMereDto').get('id')
          .errors?.required
      "
    >
      <kodit-jedinica-mere-dropdown
        [jedinicaMereGroup]="
          robaForm.controls.predmetStavkeDto.get('jedinicaMereDto')
        "
        formCtrlId="jedinicaMereDto"
        [jedinicaMereDefaultValue]="jmDefaultValue"
      ></kodit-jedinica-mere-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Stopa PDV-a" *ngIf="showStopaPdv">
      <kodit-pdv-stopa-dropdown
        [pdvStopaCtrl]="robaForm.controls.predmetStavkeDto.get('stopaPDV')"
        [shouldSetDefaultValue]="shouldSetDefaultValue"
      ></kodit-pdv-stopa-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Oznaka PDV kategorije" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-kategorija-dropdown
        [pdvKategorijaCtrl]="robaForm.controls.predmetStavkeDto.get('oznakaPdvKategorije')"
      ></kodit-pdv-kategorija-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Razlog PDV izuzeća" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-razlog-izuzeca-dropdown
        [isVisible]="mode === editModeValue ? false : true"
        [pdvRazlogIzuzecaCtrl]="robaForm?.controls?.predmetStavkeDto?.get('razlogPdvIzuzecaId')"
        [oznakaPdvKategorije]="robaForm?.controls?.predmetStavkeDto?.get('oznakaPdvKategorije').value"
      >
      </kodit-pdv-razlog-izuzeca-dropdown>
    </kodit-field>

    <hr />

    <kodit-field fieldLabelFor="nabavnaCena" fieldLabel="Poslednja nabavna cena">
      <kodit-number-input
        formCtrlId="nabavnaCena"
        [formCtrl]="robaForm.controls.predmetStavkeDto.get('nabavnaCena')"
      ></kodit-number-input>
    </kodit-field>

    <kodit-field fieldLabelFor="prodajnaCenaBezPdv" fieldLabel="Prodajna cena bez PDV-a">
      <kodit-number-input
        formCtrlId="prodajnaCenaBezPdv"
        [formCtrl]="
          robaForm.controls.predmetStavkeDto.get('prodajnaCenaBezPdv')
        "
        (onInputLeft)="handleProdajnaCenaChanged(null)"
      ></kodit-number-input>
    </kodit-field>

    <kodit-field fieldLabelFor="prodajnaCenaBezPdv" fieldLabel="Prodajna cena sa PDV-om">
      <kodit-number-input
        formCtrlId="prodajnaCenaSaPdv"
        [formCtrl]="
          robaForm.controls.predmetStavkeDto.get('prodajnaCenaSaPdv')
        "
        (onInputLeft)="handleProdajnaCenaSaPdvChanged(null)"
      ></kodit-number-input>
    </kodit-field>

    <hr />

    <kodit-number-input-field
      formCtrlId="netoTezinaKg"
      formCtrlLabel="Neto težina u kg"
      [formCtrl]="robaForm.controls.netoTezinaKg"
    ></kodit-number-input-field>

    <kodit-valuta-dropdown-field
      [valutaCtrl]="robaForm.controls.predmetStavkeDto.get('valutaId')"
    ></kodit-valuta-dropdown-field>

    <kodit-text-input-field
      formCtrlLabel="Tarifni broj"
      [formCtrl]="robaForm.controls.tarifniBrojStr"
    ></kodit-text-input-field>

    <kodit-oznaka-kategorija-autocomplete
      [oznakaGroup]="robaForm.controls.kategorija"
      formCtrlId="kategorija"
    >
    </kodit-oznaka-kategorija-autocomplete>

    <!-- Magacini -->
    <kodit-field fieldLabel="Magacini">
      <kodit-magacini-multiselect
        formCtrlId="magacini"
        [magaciniFormArray]="robaForm.controls.magacini"
        (selectedMagacini)="selectedMagacini($event)"
      ></kodit-magacini-multiselect>
    </kodit-field>

    <!-- Repromaterijali -->
    <kodit-field fieldLabel="Repromaterijali">
      <kodit-repromaterijal-robe-multiselect
        formCtrlId="repromaterijali"
        [repromaterijaliFormArray]="robaForm.controls.repromaterijali"
        (selectedRepromaterijali)="selectedRepromaterijali($event)"
      ></kodit-repromaterijal-robe-multiselect>
    </kodit-field>

    <div class="p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <label for="naziv" kodit-form-label>Šifra</label>
        <kodit-text-input
          formCtrlId="sifra"
          [formCtrl]="robaForm.controls.predmetStavkeDto.get('sifra')"
        ></kodit-text-input>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <label for="gtin" kodit-form-label>GTIN</label>
        <kodit-text-input
          formCtrlId="gtin"
          [formCtrl]="robaForm.controls.gtin"
        ></kodit-text-input>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <label for="externalId" kodit-form-label>Eksterni ID</label>
        <kodit-only-numbers-text-input
          formCtrlId="externalId"
          [formCtrl]="robaForm.controls.predmetStavkeDto.get('externalId')"
        ></kodit-only-numbers-text-input>
      </div>
    </div>

    <div class="p-field">
      <label for="napomena" kodit-form-label>Opis</label>
      <textarea
        id="napomena"
        rows="5"
        pInputText
        autoResize="autoResize"
        [formControl]="robaForm.controls.napomena"
      ></textarea>
    </div>
  </div>
</form>
