import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { CreateStanCommand, InfoZgradaClient, IStanDto, StanDto, UpdateStanCommand } from '@kodit/core/data-api';
import { AlertService, Mode, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  FizickoLiceFormService,
  FormHelper,
} from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-stan-form',
  templateUrl: './stan-form.component.html',
  styleUrls: ['./stan-form.component.scss'],
})
export class StanFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IStanDto>;
  mode: Mode = Mode.NEW;

  constructor(
    private _alertService: AlertService,
    private _client: InfoZgradaClient,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _fs: FizickoLiceFormService,
    private _formHelper: FormHelper,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this._initForm();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    if (!this.form!.valid) {
      this._formHelper.invalidateForm(this.form!);
      return;
    }

    if (this.mode === Mode.NEW) {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje stana...');
      this._subs.add(
        this._client.createStan(new CreateStanCommand({
            fizickoLiceId: this._dialogConfig.data.strankaId,
            stan: new StanDto(this.form.value)
          }
        ))
        .subscribe(res => {
          if(res.succeeded){
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(true);
            return;
          }
          this._alertService.addWarnMsg(res.messages[0]);
        })
      )
      return;
    }

    this._sharedService.displayLoadingScreen(true, 'Ažuriranje stana...');
    this._subs.add(
      this._client.updateStan(new UpdateStanCommand({
          fizickoLiceId: this._dialogConfig.data.strankaId,
          stan: new StanDto(this.form.value)
        }
      ))
        .subscribe(res => {
          if(res.succeeded){
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(true);
            return;
          }
          this._alertService.addWarnMsg(res.messages[0]);
        })
    )
  }

  private _initForm() {
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this._subs.add(
        this._client.getStanById(this._dialogConfig.data.id)
        .subscribe(res => {
          if(res.succeeded){
            this.form = this._fs.GetStanFormGroup(res.data);
          }
        })
      );
      this.mode = Mode.EDIT;
      return;
    }
    this.form = this._fs.GetStanFormGroup();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.form = null;
  }
}
