import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SignalrService } from '../signalr.service';
import { AfterLoginService } from './after-login.service';
import { KorisniciClient, PackageType } from '@kodit/core/data-api';
import { TokenService } from '../..';
import { Store } from '@ngrx/store';
import { deleteAllFilters } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

export const SESSION_ID_NAME = 'eKompanija_userSessionId';
export const TOKEN_NAME = 'eKompanija_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _refreshTokenName = 'eKompanija_refreshToken';

  constructor(
    private _jwtHelper: JwtHelperService,
    private _router: Router,
    private _korisniciClient: KorisniciClient,
    private _signalRService: SignalrService,
    private _afterLoginService: AfterLoginService, //private _coreService : CoreService
    private _tokenService: TokenService,
    private _storage: Store
  ) {}

  public isUserAuthenticated = (): boolean => {
    return this.getToken && !this._jwtHelper.isTokenExpired(this.getToken);
  };

  public logout() {
    if (!!localStorage.getItem(SESSION_ID_NAME)) {
      this._korisniciClient
        .logoutUser(localStorage.getItem(SESSION_ID_NAME))
        .subscribe(() => {});
    }
    this._tokenService.setShowLogoutDialog = false;
    this.removeTokens();
    this._storage.dispatch(deleteAllFilters());
    this._router.navigate(['/autentikacija', 'prijava']);
  }

  /**
   * Vraca da li je user authenticated
   */
  appInitialized$ = new BehaviorSubject<boolean>(false);

  set setAppInitialized(value: boolean) {
    this.appInitialized$.next(value);
  }

  get getAppInitialized() {
    return this.appInitialized$.asObservable();
  }

  getAppInitializedValue() {
    return this.appInitialized$.value;
  }

  get getUserClaims(): string[] {
    const decodedJWT = this._jwtHelper.decodeToken(this.getToken);
    return decodedJWT?.Permission;
  }

  get getCurrentTenant(): string {
    const decodedJWT = this._jwtHelper.decodeToken(this.getToken);
    return decodedJWT?.tenantKey;
  }

  get getCurrentTenantName(): string {
    const decodedJWT = this._jwtHelper.decodeToken(this.getToken);
    return decodedJWT?.tenantName;
  }

  get getUserData(): { email: string; fullName: string } {
    const decodedJWT = this._jwtHelper.decodeToken(this.getToken);
    return {
      email:
        decodedJWT?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ],
      fullName: decodedJWT?.fullName,
    };
  }

  get getUserPackages(): string[] {
    const decodedJWT = this._jwtHelper.decodeToken(this.getToken);
    return decodedJWT.packages;
  }

  get getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  get getRefreshToken(): string {
    return localStorage.getItem(this._refreshTokenName);
  }

  get getSessionId(): string {
    return localStorage.getItem(SESSION_ID_NAME);
  }

  shouldUpdatePassword$ = new BehaviorSubject<boolean>(false);

  get getShouldUpdatePassword() {
    return this.shouldUpdatePassword$.asObservable();
  }

  set setShouldUpdatePassword(data: boolean) {
    this.shouldUpdatePassword$.next(data);
  }

  setTokens(token: string, refreshToken: string, sessionId: string) {
    // stop signalR connection if any
    this._signalRService.closeConnection();
    localStorage.setItem(TOKEN_NAME, token);
    localStorage.setItem(this._refreshTokenName, refreshToken);
    localStorage.setItem(SESSION_ID_NAME, sessionId);
    this.startSignalR();
    this._afterLoginService.getDataAfterLogin();
  }

  removeTokens() {
    // stop signalR connection
    this._signalRService.closeConnection();
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(this._refreshTokenName);
    localStorage.removeItem(SESSION_ID_NAME);
    this.setAppInitialized = false;
  }

  /**
   * U slucaju da dode do greske, da mozemo da prikazemo odgovarajucu poruku
   */
  userChanged$ = new BehaviorSubject<boolean>(false);

  get getUserChanged() {
    return this.userChanged$.asObservable();
  }

  set setUserChanged(data: boolean) {
    this.userChanged$.next(data);
  }

  // This method can be called a couple of different ways
  // *hasClaim="'claimType'"  // Assumes claimValue is true
  // *hasClaim="'claimType:value'" // Compares claimValue to value
  // *hasClaim="['claimType1','claimType2:value','claimType3']"
  hasClaim(claimValue: string | string[]): boolean {
    if (!this.isUserAuthenticated()) {
      return false;
    }

    if (this.getUserClaims.includes('ALL')) {
      return true;
    }

    let userHasClaim: boolean = false;

    // See if an array of values was passed in.
    if (typeof claimValue === 'string') {
      userHasClaim = this.isClaimValid(claimValue);
    } else {
      let claims: string[] = claimValue;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          userHasClaim = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (userHasClaim) {
            break;
          }
        }
      }
    }
    return userHasClaim;
  }

  private isClaimValid(claimValue: string): boolean {
    let ret: boolean = false;
    const regEx = new RegExp(`\\b${claimValue}\\b`);

    for (let i = 0; i < this.getUserClaims?.length; ++i) {
      if (regEx.test(this.getUserClaims[i])) {
        ret = true;
        break;
      }
    }
    return ret;
  }

  /**
   * start signalR connection
   */
  public startSignalR() {
    this._signalRService.startConnection(this.getCurrentTenant, this.getToken);
  }

  hasPackage(requiredPackages: string | string[]): boolean {
    if (!this.isUserAuthenticated()) {
      return false;
    }

    if (this.getUserPackages.includes('ROOT')) {
      return true;
    }

    return this.containsPackages(requiredPackages);
  }

  hasInfoZgrada(): boolean {
    return this.containsPackages(PackageType[PackageType.INFOZGRADA]);
  }

  private containsPackages(requiredPackages: string | string[]): boolean {
    const packages = this.getUserPackages;
    if (typeof requiredPackages === 'string') {
      return packages.includes(requiredPackages);
    } else {
      for (let index = 0; index < requiredPackages.length; index++) {
        // If one is successful, then let them in
        if (packages.includes(requiredPackages[index])) {
          return true;
        }
      }
    }
    return false;
  }

  hasAllOtherPackagesExcept(requiredPackages: string | string[]): boolean {
    const packages = this.getUserPackages;

    if (packages.includes('ROOT')) {
      return true;
    }

    return !this.hasPackage(requiredPackages);
  }
}
