import { Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IMailReceiverDto,
  KontaktTableDto,
  MagacinDto,
  PackageType,
  PoslovnaJedinicaTableDto,
  SaldoStrankaDto,
  SaldoStrankeClient,
  StrankaClient,
  StrankaInfoDto,
  TipPristupa,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { RacunTableService } from 'libs/core/moduli/racun/src/lib/racun-table.service';
import {
  DialogSize,
  OnDynamicDialogTypedInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SlanjePodsetnikaFormComponent } from '../slanje-podsetnika-form/slanje-podsetnika-form.component';
import { TabPanel } from 'primeng/tabview';

@Component({
  selector: 'kodit-stranka-info',
  templateUrl: './stranka-info.component.html',
  styleUrls: ['./stranka-info.component.scss'],
})
export class StrankaInfoComponent
  implements
    OnInit,
    OnDestroy,
    OnDynamicDialogTypedInit<SlanjePodsetnikaFormComponent> {
  private _subs: Subscription = new Subscription();
  private _saldoSubs = new Subscription();

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  strankaInfoDto: StrankaInfoDto;
  activeIndex: number = 0;
  izlazniRacun: VrstaRacuna = VrstaRacuna.IZLAZNI;
  ulazniRacun: VrstaRacuna = VrstaRacuna.ULAZNI;
  avansniRacun: TipRacuna = TipRacuna.AVANSNI_RACUN;
  poslovneJedinice: PoslovnaJedinicaTableDto[] = [];
  saldoStrankaDto: SaldoStrankaDto;
  saldoValuta: string;
  strankaId: number;
  kontaktTable: KontaktTableDto[] = [];
  magacinDto: MagacinDto;
  innerWidth: any;

  /** Conditionals props */
  hasBesplatniPaket: boolean = false;
  shouldDisplayDobavljac: boolean;
  shouldDisplayPrimljeniAvans: boolean;

  /** I/O */
  @ViewChild('kupacPanel') kupacPanel: TabPanel;
  @ViewChild('dobavljacPanel') dobavljacPanel: TabPanel;
  @ViewChild('primljeniAvansPanel') primljeniAvansPanel: TabPanel;
  @ViewChild('datiAvansPanel') datiAvansPanel: TabPanel;

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _client: StrankaClient,
    private _saldoClient: SaldoStrankeClient,
    private _breadcrumbService: BreadcrumbService,
    private _tableService: RacunTableService,
    public authService: AuthService
  ) {
    this.strankaInfoDto = new StrankaInfoDto();
  }

  ngOnInit(): void {
    this.hasBesplatniPaket =
      this.authService.hasPackage(PackageType[PackageType.DZABE]) &&
      !this.authService.hasPackage(PackageType[PackageType.ROOT]);
      this.shouldDisplayPrimljeniAvans = this.authService.hasClaim([
        TipPristupa[TipPristupa.ULAZNA_FAKTURA_CRUD],
        TipPristupa[TipPristupa.ULAZNA_FAKTURA_VIEW],
      ]);
      this.shouldDisplayDobavljac = this.shouldDisplayPrimljeniAvans;
    // get router data
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });
    //this._loadSaldo();
    this._load();
    this.innerWidth = window.innerWidth;
  }

  // onEditPravnoLice() {
  //   this.dialogConfig.data = {
  //     id: this.pravnoLiceModel.id,
  //     tipPravnogLica: this.pravnoLiceModel.tipPravnogLica,
  //   };
  //   this.dialogConfig.header = 'Izmena podataka pravnog lica';
  //   const ref = this._dialogService.open(
  //     PravnoLiceFormComponent,
  //     this.dialogConfig
  //   );
  //   this._dialogCloseSub = ref.onClose.subscribe(
  //     (dto: ResultOfPravnoLiceVm) => {
  //       if (dto) {
  //         this.pravnoLiceModel = dto.data.pravnoLiceDto;
  //       }
  //     }
  //   );
  // }

  // onDelete() {
  //   this._confirmationService.confirm({
  //     message: 'Da li ste sigurni?',
  //     header: 'Potvrdite brisanje',
  //     acceptLabel: 'Da',
  //     rejectLabel: 'Ne',
  //     icon: 'pi pi-exclamation-triangle',
  //     acceptButtonStyleClass: 'p-button-success',
  //     rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
  //     accept: () => {
  //       this.onDeletePravnoLice();
  //     },
  //   });
  // }

  // onDeletePravnoLice() {
  //   this._deleteSub = this._pravnaLicaClient.delete(this.pravnoLiceModel.id).subscribe(
  //     () => {
  //       this._alertService.addSuccessMsg(
  //         `Pravno lice ${this.pravnoLiceModel.naziv} uspešno obrisano.`
  //       );
  //     },
  //     (error) => {
  //       this._alertService.addFailedMsg(error);
  //     }
  //   );
  // }

  onPosaljiPodsetnik() {
    this.dialogConfig.header = 'Slanje podsetnika';
    let sendTo: IMailReceiverDto[];
    let sendCc: IMailReceiverDto[];
    if (this.kontaktTable.length > 0) {
      sendTo = [
        {
          email: this.kontaktTable[0]?.email,
          name: this.kontaktTable[0]?.naziv,
        },
      ];

      sendCc = this.kontaktTable
        .filter((k, i) => i > 0)
        .map((k, i) => {
          return {
            email: k.email,
            name: k.naziv,
          };
        });
    }
    this.dialogConfig.data = {
      strankaId: this.strankaId,
      sendTo: sendTo,
      sendCc: sendCc,
    };
    this.dialogConfig.customSubmitButton = {
      icon: 'fas fa-paper-plane',
      label: 'Pošalji',
    };
    this.openDialog(SlanjePodsetnikaFormComponent);
  }

  openDialog(formType: Type<SlanjePodsetnikaFormComponent>): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
        }
      })
    );
  }

  private _loadSaldo() {
    this._saldoSubs.add(
      this._saldoClient.getSaldoStranke(this.strankaId).subscribe((res) => {
        this.saldoStrankaDto = res.data.saldoStrankaDto;
        if (res.data.valutaAlphaChar) {
          this.saldoValuta = res.data.valutaAlphaChar;
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getStrankaInfo(this.strankaId).subscribe((res) => {
        this.strankaInfoDto = res.data;
        this.setBr();
      })
    );
  }

  private setBr() {
    this._breadcrumbService.set('@nazivStranke', this.strankaInfoDto.naziv);
    this._breadcrumbService.set('@PravnoLiceId', this.strankaInfoDto.naziv);
  }

  handleIndexChange(tabIndex: number) {
    this._handleActiveIndex();
    this._tableService.setActiveTab = this.activeIndex;
  }

  private _handleActiveIndex() {
    if (this.kupacPanel && this.kupacPanel.selected) {
      this.activeIndex = 0;
    } else if (this.dobavljacPanel && this.dobavljacPanel.selected) {
      this.activeIndex = 1;
    } else if (this.primljeniAvansPanel && this.primljeniAvansPanel.selected) {
      this.activeIndex = 2;
    } else if (this.datiAvansPanel && this.datiAvansPanel.selected) {
      this.activeIndex = 3;
    }
  }

  handleChange(event: any) {
    debugger;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
