import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { IStrankaRacunaDto, RacuniClient } from '@kodit/core/data-api';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { IRequest } from 'libs/core/moduli/racun/src/lib/ui/stranka-racuna/stranka-racuna-autocomplete/stranka-racuna-autocomplete.component';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-posaljilac-dokumenta-autocomplete',
  templateUrl: './posaljilac-dokumenta-autocomplete.component.html',
  styleUrls: ['./posaljilac-dokumenta-autocomplete.component.scss'],
})
export class PosaljilacDokumentaAutocompleteComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  pravnaLicaSuggestions: IStrankaRacunaDto[] = [];
  ctrlPlaceholder: string = 'Matični broj, PIB ili naziv...';
  ctrlDisabled: boolean = false;
  formGroup: FormGroupTypeSafe<IRequest> | undefined;
  form: FormGroupTypeSafe<IRequest>;

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() parentCtrl: any = 'body';
  @Input() mustSelect: boolean = false;

  constructor(private _client: RacuniClient, private _fb: FormBuilderTypeSafe) {
    this.form = this._fb.group<IRequest>({
      searchValue: new FormControl(null, {
        validators: Validators.required,
      }),
    });
  }

  ngOnInit(): void {
    this.form.controls.searchValue.patchValue(this.formCtrl?.value.naziv);
  }

  searchPravnoLice(event: any) {
    this._subs = this._client
      .searchStrankaRacuna(event.query)
      .subscribe((result) => {
        this.pravnaLicaSuggestions = result.data!.strankaRacunaDtoList!;
      });
  }

  selectionChanged(value: IStrankaRacunaDto) {
    this.formCtrl?.patchValue(value);
    this.form.controls.searchValue.patchValue(value.naziv);
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
