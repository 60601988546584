/* eslint-disable max-len */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  EmailClient,
  GetIzlazniRacunUblQuery,
  GetRacunQuery,
  IMailReceiverDto,
  IRacunInfoDto,
  IRelevantniDokumentListItemDto,
  PostmarkOutboundMessageDetailDto,
  RacuniClient,
  StatusRacuna,
  TipRacuna,
  UlazneFaktureClient,
  TipRelevantnogDokumenta,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import * as printJS from 'print-js';
import { RacunInfoService } from './racun-info.service';
import { SERBIA_CURRENCY_ALPHA_CHAR } from '@kodit/core/shared';
import { MenuItem } from 'primeng/api';
import { PosaljiElektronskiFormComponent } from '../../../../../../shared-subforms/src/lib/racun/posalji-elektronski-form/posalji-elektronski-form.component';
import { PosaljiNaSefFormComponent } from '../../../../../../shared-subforms/src/lib/racun/posalji-na-sef-form/posalji-na-sef-form.component';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';

@Component({
  selector: 'kodit-racun-info',
  templateUrl: './racun-info.component.html',
  styleUrls: ['./racun-info.component.scss'],
})
export class RacunInfoComponent implements OnInit, OnDestroy {
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  racunInfoDto: IRacunInfoDto;
  pdfInnerHtml: SafeHtml;
  pdfFileUrl: string;
  previewUrl: string;
  pdfFileName: string;
  xmlInnerHtml: SafeHtml;
  xmlFileUrl: string;
  xmlFileName: string;
  uploadCompleted: boolean;
  racunImage: string;
  items: any[];
  posaljiItems: MenuItem[];
  pdfNaEngleskom: boolean;
  shouldDownload: boolean;
  relevantniDokumenti: IRelevantniDokumentListItemDto[] = [];
  emailPorukeRacuna: PostmarkOutboundMessageDetailDto[] = [];
  racunId = 0;
  firstView : boolean = true;

  //#region Should display fields
  shouldDisplayAkcije: boolean;
  shouldDisplayDownloadUbl: boolean;
  shouldDisplayEdit: boolean;
  shouldDisplayUplatePoRacunu: boolean;
  shouldDisplayIskoriscenostAvansnogRacuna: boolean;
  shouldDisplayAvansneUplateRacuna: boolean;
  shouldDisplayUpload: boolean;
  shouldDisplayUploadSkeleton: boolean;
  shouldDisplayRacunImage: boolean;
  shouldDisplayRacunPdf: boolean;
  shouldDisablePreviewAndDownload: boolean = true;
  shouldDisableDownloadUbl: boolean = true;
  shouldDisplayPDFDownloadDropdown: boolean;
  shouldDisplayEmptyDocumentMsg: boolean = false;
  shouldDisplayPosaljiButton: boolean = false;

  /** Props */
  private _subs: Subscription = new Subscription();

  //#endregion Should display fields

  constructor(
    private _alertService: AlertService,
    private _route: ActivatedRoute,
    private _sharedService: SharedService,
    private _client: RacuniClient,
    private _emailClient: EmailClient,
    private _domSanitizer: DomSanitizer,
    private _breadcrumbService: BreadcrumbService,
    private _locationService: LocationService,
    private _racunInfoService: RacunInfoService,
    private _dialogService: DynamicDialogService,
    private _ulazneClient: UlazneFaktureClient
  ) {}

  private get _getshouldDisplayPDFDownloadDropdown() {
    return this.racunInfoDto.valutaAlfaChar !== SERBIA_CURRENCY_ALPHA_CHAR;
  }

  //#region Akcije

  private get _getShouldDisplayAkcije() {
    return !this._getJeUlazniRacun;
  }

  private get _getShouldDisplayDownloadUbl() {
    return !this._getJePredracun && !this._getJeRadniNalog;
  }

  private get _getShouldDisplayEdit() {
    // Ne prikazujemo izmenu za radne naloge na stranici info racuna (trenutno neizvodljivo jer nemamo statusRadnogNaloga u IRacunInfoDto -> prosiriti ??)

    if (
      (this.racunInfoDto.tip === TipRacuna.IZLAZNA_FAKTURA &&
        this.racunInfoDto.vezaniRacuniDto.some(
          (el) => el.tip === TipRacuna.OTPREMNICA
        )) ||
      (this.racunInfoDto.tip === TipRacuna.OTPREMNICA &&
        this.racunInfoDto.vezaniRacuniDto.some(
          (el) => el.tip === TipRacuna.IZLAZNA_FAKTURA
        ))
    ) {
      return false;
    }

    return (
      this.racunInfoDto.statusDto.status === StatusRacuna.NACRT &&
      !this._getJeAvansniRacun &&
      !this._getJeRadniNalog
    );
  }

  private get _getShouldDisplayUplatePoRacunu() {
    return !this._getJeAvansniRacun && !this._getJeOtpremnica;
  }

  //#endregion Akcije

  //#region Should display helpers

  private get _getShouldDisplayIskoriscenostAvansnogRacuna() {
    return this._getJeAvansniRacun;
  }

  private get _getShouldDisplayPosaljiButton() {
    return this.racunInfoDto.statusDto.status === StatusRacuna.NACRT;
  }

  private get _getShouldDisplayAvansneUplateRacuna() {
    return this.racunInfoDto.hasAvansnuUplatu;
  }

  private get _getShouldDisplayUpload() {
    return this._getJeUlazniRacun && !this.pdfInnerHtml && !this.racunImage;
  }

  private get _getShouldDisplayUploadSkeleton() {
    return !this.pdfInnerHtml && !this._getJeUlazniRacun;
  }

  private get _getShouldDisplayRacunImage() {
    return this.racunImage != undefined && this.racunImage != null;
  }

  private get _getShouldDisplayRacunPdf() {
    return this.pdfInnerHtml != undefined && this.pdfInnerHtml != null;
  }

  private get _getShouldDisablePreviewAndDownload() {
    return this.pdfFileUrl === undefined;
  }

  private get _getShouldDisableDownloadUbl() {
    return this.xmlFileUrl === undefined;
  }

  public get getCanDeleteOznake(){
    return this.racunInfoDto.statusDto.status !== StatusRacuna.NACRT;
  }

  private get _getJeAvansniRacun() {
    return this.racunInfoDto.tip === TipRacuna.AVANSNI_RACUN;
  }

  private get _getJeOtpremnica() {
    return this.racunInfoDto.tip === TipRacuna.OTPREMNICA;
  }

  private get _getJePocetnoStanje() {
    return (
      this.racunInfoDto.vrsta === VrstaRacuna.POCETNO_STANJE_IZLAZNI ||
      this.racunInfoDto.vrsta === VrstaRacuna.POCETNO_STANJE_ULAZNI
    );
  }

  private get _getJeUlazniRacun() {
    return (
      this.racunInfoDto.tip === TipRacuna.ULAZNA_FAKTURA ||
      (this.racunInfoDto.vrsta === VrstaRacuna.ULAZNI &&
        (this._getJeAvansniRacun ||
          this.racunInfoDto.tip === TipRacuna.DOKUMENT_O_SMANJENJU ||
          this.racunInfoDto.tip === TipRacuna.DOKUMENT_O_POVECANJU))
    );
  }

  private get _getJePredracun() {
    return this.racunInfoDto.tip === TipRacuna.PREDRACUN;
  }

  private get _getJeRadniNalog() {
    return this.racunInfoDto.tip === TipRacuna.RADNI_NALOG;
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.racunId = Number.parseInt(params['id']);
        this._racunInfoService.setRacunId = this.racunId;
        this._load();
        this._getEmailPorukeZaRacun();
      }
    });

    this._racunInfoService.getReloadedDocuments.subscribe((racunId: number) => {
      if (racunId && this.racunId === racunId) {
        this._getRelevantneDokumente();
      }
    });
  }

  onEdit() {
    this._locationService.routeToRacunEdit(
      this.racunInfoDto.id,
      this.racunInfoDto.tip,
      this.racunInfoDto.vrsta
    );
  }

  onDownload() {
    this._sharedService.downloadUrlAsPDF(
      this.pdfFileUrl,
      this.pdfFileName ?? this.racunInfoDto.broj
    );
  }

  onUblDownload() {
    if (!this.xmlFileUrl) {
      this._getRacunXml();
      return;
    }

    this._sharedService.downloadUrlAsXml(
      this.xmlFileUrl,
      this.xmlFileName ?? this.racunInfoDto.broj
    );
  }

  onPreview() {
    printJS(this.pdfFileUrl);
  }

  ngOnDestroy() {
    this.racunId = undefined;
    this._racunInfoService.setRacunInfoDto = null;
    this._subs.unsubscribe();
  }

  openDialog(
    formType:
      | Type<PosaljiElektronskiFormComponent>
      | Type<PosaljiNaSefFormComponent>
  ): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe(() => {
        this._load();
      })
    );
  }

  private _loadItems() {
    this.items = [
      {
        label: 'Skini na srpskom',
        icon: 'fa-light fa-download',
        command: () => {
          this._getRacunPdf(
            (this.pdfNaEngleskom = false),
            (this.shouldDownload = true)
          );
        },
      },
      {
        label: 'Skini na engleskom',
        icon: 'fa-light fa-download',
        command: () => {
          this._getRacunPdf(
            (this.pdfNaEngleskom = true),
            (this.shouldDownload = true)
          );
        },
      },
    ];
  }

  //#endregion Should display functions

  //#region Private helpers

  private _loadPosaljiItems() {
    const allPosaljiItems = [
      {
        label: 'Pošalji na Mail',
        icon: 'pi pi-envelope',
        command: () => {
          this._openSendToMailDialog();
        },
      },
      {
        label: 'Pošalji na SEF',
        icon: 'far fa-paper-plane',
        command: () => {
          this._openSendToSefDialog();
        },
      },
    ];
    // ukoliko racun ne moze da se posalje na sef ne prikazati tu mogucnost
    this.racunInfoDto?.mozeNaSef &&
    this.racunInfoDto.tip !== TipRacuna.PREDRACUN &&
    this.racunInfoDto.tip !== TipRacuna.OTPREMNICA
      ? (this.posaljiItems = allPosaljiItems)
      : (this.posaljiItems = allPosaljiItems.slice(0, -1));
  }

  private _prepareContent() {
    this.shouldDisplayPDFDownloadDropdown = this._getshouldDisplayPDFDownloadDropdown;
    this.shouldDisplayAkcije = this._getShouldDisplayAkcije;
    this.shouldDisplayDownloadUbl = this._getShouldDisplayDownloadUbl;
    this.shouldDisplayEdit = this._getShouldDisplayEdit;
    this.shouldDisplayUplatePoRacunu = this._getShouldDisplayUplatePoRacunu;
    this.shouldDisplayIskoriscenostAvansnogRacuna = this._getShouldDisplayIskoriscenostAvansnogRacuna;
    this.shouldDisplayAvansneUplateRacuna = this._getShouldDisplayAvansneUplateRacuna;
    this.shouldDisplayUploadSkeleton = this._getShouldDisplayUploadSkeleton;
    this.shouldDisplayPosaljiButton = this._getShouldDisplayPosaljiButton;
  }

  private _load() {
    this._subs.add(
      this._client
        .getRacunInfo(new GetRacunQuery({ id: this.racunId }))
        .subscribe((res) => {
          this.racunInfoDto = res.data;
          this._racunInfoService.setRacunInfoDto = res.data;

          this._setBreadcrumb();
          this._prepareContent();

          if (this.racunInfoDto.statusDto.status !== StatusRacuna.NACRT) {
            this._getRelevantneDokumente();
          }

          if (
            this._getJePocetnoStanje
          ) {
            return;
          }
          // ubl mozemo da skinemo samo za one racune koji imaju stranku i nisu radni nalog
          if (
            this.racunInfoDto.strankaNaziv &&
            this.racunInfoDto.tip !== TipRacuna.RADNI_NALOG
          ) {
            // ubl uzimamo samo za domace izlazne racune
            this.shouldDisableDownloadUbl = false;
          }

          if(this.racunInfoDto.tip == TipRacuna.IZLAZNA_FAKTURA && this.racunInfoDto.izlazniRacunIdSef == 0){
            this.shouldDisableDownloadUbl = true;
          }

          if(this.racunInfoDto.vrsta !== VrstaRacuna.ULAZNI){
            this._racunInfoService.getVezaniRacuniChanged.subscribe((result) => {
              if (this.firstView || result) {
                if (this.racunInfoDto.statusDto.status === StatusRacuna.NACRT) {
                  this._getRacunPdf(
                    res.data.valutaAlfaChar != SERBIA_CURRENCY_ALPHA_CHAR,
                    this.shouldDownload
                  );
                }
              }
            })
          }
          this.firstView = false;

          this._loadItems();
          this._loadPosaljiItems();
        })
    );
  }

  // PDF racuna se generise na BE
  private _getRacunPdf(pdfStrani: boolean, shouldDownload: boolean) {
    this._subs.add(
      this._client
        .getPdf(this.racunInfoDto.id, pdfStrani)
        .subscribe((result) => {
          this.pdfFileUrl = URL.createObjectURL(result.data);
          this._setPdf(result.data);
          if (shouldDownload) {
            this.onDownload();
          }
        })
    );
  }

  public _getRacunXml() {
    if(this.racunInfoDto.vrsta == VrstaRacuna.ULAZNI){
      this._subs.add(
        this._ulazneClient
          .getUlazniRacunUblQuery(this.racunInfoDto.id)
          .subscribe((result) => {
            this.xmlFileUrl = URL.createObjectURL(result.data);
            this.xmlFileName = (result.data as any).fileName;
  
            this.shouldDisableDownloadUbl = this._getShouldDisableDownloadUbl;
  
            this._sharedService.downloadUrlAsXml(
              this.xmlFileUrl,
              this.xmlFileName ?? this.racunInfoDto.broj
            );
          })
      );
    }else{
      this._subs.add(
        this._client
          .getIzlazniRacunUbl(
            new GetIzlazniRacunUblQuery({
              racunId: this.racunInfoDto.id,
            })
          )
          .subscribe((result) => {
            this.xmlFileUrl = URL.createObjectURL(result.data);
            this.xmlFileName = (result.data as any).fileName;
            // this.xmlInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
            //   "<object  data='" +
            //     this.pdfFileUrl +
            //     "' type='application/xml' class='embed-responsive-item' >" +
            //     'Object ' +
            //     this.pdfFileUrl +
            //     ' failed' +
            //     '</object>'
            // );

            this.shouldDisableDownloadUbl = this._getShouldDisableDownloadUbl;

            this._sharedService.downloadUrlAsXml(
              this.xmlFileUrl,
              this.xmlFileName ?? this.racunInfoDto.broj
            );
          })
      );
    }
  }

  private _setPdfFromStorage(originalFileName: string) {
    if (!originalFileName) {
      this._alertService.addWarnMsg('Ne postoji podrazumevani pdf fajl');
      return;
    }

    this._subs.add(
      this._client
        .getRelevantniDokumentUrl(originalFileName)
        .subscribe((res) => {
          this._setPdfUrl(res.data.file);
        })
    );
  }

  private _setPdfUrl(url: string) {
    this.pdfInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      `<object data="${url}" type="application/pdf" class="embed-responsive-item">
     Object ${url} failed
   </object>`
    );

    this.shouldDisplayRacunPdf = this._getShouldDisplayRacunPdf;
    this.shouldDisplayUpload = this._getShouldDisplayUpload;
    this.shouldDisplayUploadSkeleton = this._getShouldDisplayUploadSkeleton;
    this.shouldDisablePreviewAndDownload = this._getShouldDisablePreviewAndDownload;
  }

  private _setPdf(blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    this.pdfInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      "<object  data='" +
        blobUrl +
        "' type='application/pdf' class='embed-responsive-item' >" +
        'Object ' +
        blobUrl +
        ' failed' +
        '</object>'
    );

    this.shouldDisplayRacunPdf = this._getShouldDisplayRacunPdf;
    this.shouldDisplayUpload = this._getShouldDisplayUpload;
    this.shouldDisplayUploadSkeleton = this._getShouldDisplayUploadSkeleton;
    this.shouldDisablePreviewAndDownload = this._getShouldDisablePreviewAndDownload;
  }

  private _getRelevantneDokumente() {
    this._subs.add(
      this._client.getRelevantneDokumente(this.racunId).subscribe((res) => {
        if (res.succeeded) {
          this.relevantniDokumenti = res.data.responseList;
          this._setPdfFromStorage(
            res.data.responseList.find((x) => x.tipRelevantnogDokumenta == TipRelevantnogDokumenta.FAKTURA_RS).storageFileName
          );
        }
        // ako res nije succeeded, znaci da nemamo nista u storage,a trebali bismo pa uploadujemo
        else {
          this._sharedService.displayLoadingScreen(
            true,
            'Pribavljanje dokumenata...'
          );
          this._subs.add(
            this._client.uploadMissingPdf(this.racunId).subscribe(res => {
              if(res.succeeded){
                this.relevantniDokumenti = res.data
                  .responseList as IRelevantniDokumentListItemDto[];
                this._setPdfUrl(
                  res.data.responseList.find((x) => x.tipRelevantnogDokumenta == TipRelevantnogDokumenta.FAKTURA_RS).file
                );
              }
            })
          )
        }
      })
    );
  }

  //#endregion Private helpers

  private _getEmailPorukeZaRacun() {
    this._subs.add(
      this._emailClient.getEmailsForRacun(this.racunId).subscribe((res) => {
        this.emailPorukeRacuna = res.data?.emails ?? [];
      })
    );
  }

  private _openSendToMailDialog() {
    this.dialogConfig.header = 'Elektronsko slanje';
    const sendTo: IMailReceiverDto[] = [];
    const sendCc: IMailReceiverDto[] = [];
    let customPoruka: IMailReceiverDto;
    this.dialogConfig.data = {
      strankaId: this.racunInfoDto.strankaId,
      racunTip: this.racunInfoDto.tip,
      racunId: this.racunInfoDto.id,
      sendTo: sendTo,
      sendCc: sendCc,
      customPoruka: customPoruka,
      stampatiNaEngleskom: false,
    };
    this.dialogConfig.customSubmitButton = {
      icon: 'fa-solid fa-envelope',
      label: 'Pošalji',
    };
    this.openDialog(PosaljiElektronskiFormComponent);
  }

  private _openSendToSefDialog() {
    this.dialogConfig.header = `Slanje na SEF - račun ${this.racunInfoDto.broj}`;
    this.dialogConfig.customSubmitButton = {
      icon: 'fas fa-paper-plane',
      label: 'Pošalji',
      class: 'mw-90',
    };
    this.dialogConfig.data = {
      racunId: this.racunInfoDto.id,
      tip: this.racunInfoDto.tip,
      strankaId: this.racunInfoDto.strankaId,
    };
    this.openDialog(PosaljiNaSefFormComponent);
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set('@racunInfo', this.racunInfoDto.broj);
  }
}
