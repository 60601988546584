import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService, Mode } from '@kodit/core/services';
import {
  RobeClient,
  IRobaDto,
  UpdateRobaCommand,
  CreateRobaCommand,
  IGetPdvConfigurationResponse,
  IRepromaterijalDto, IMagacinDto,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  PdvStopaService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import {FormHelper, MagacinFormService, RobaFormService} from 'libs/core/form-definitions/src';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import {round, StopaPdv} from "@kodit/core/shared";

@Component({
  selector: 'kodit-roba-form',
  templateUrl: './roba-form.component.html',
  styleUrls: ['./roba-form.component.scss'],
})
export class RobaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  robaForm: FormGroupTypeSafe<IRobaDto>;
  mode: Mode = Mode.NEW;
  jmDefaultValue: string;
  pdvConfiguration: IGetPdvConfigurationResponse;

  /** Definitive props */
  editModeValue : Mode = Mode.EDIT;

  /** Conditional props */
  shouldSetDefaultValue: boolean = true;
  showStopaPdv: boolean;
  shouldShowRazlogPdvIzuzeca : boolean = false;

  constructor(
    private _client: RobeClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _robaForm: RobaFormService,
    private _magacinFS : MagacinFormService,
    private _formHelper: FormHelper,
    private _pdvService: PdvStopaService
  ) {}

  ngOnInit(): void {
    // init form
    this.robaForm = this._robaForm.GetRobaFormGroup();
    //
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getRoba(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
      this.shouldSetDefaultValue = false;
    }

    // prilikom unosa ukoliko nemamo StopaPDV stavimo da je 0
    this._setShouldShowRazlogPdvIzuzeca(this.robaForm.controls?.predmetStavkeDto?.get('stopaPDV').value);

    if (this._dialogConfig.data && this._dialogConfig.data.naziv) {
      this.robaForm.controls.predmetStavkeDto
        .get('naziv')
        .patchValue(this._dialogConfig.data.naziv);
      this.robaForm.controls.predmetStavkeDto
        .get('naziv')
        .updateValueAndValidity();
    }

    if (this._dialogConfig.data && this._dialogConfig.data.iznos) {
      this.robaForm.controls.predmetStavkeDto
        .get('nabavnaCena')
        .patchValue(this._dialogConfig.data.iznos);
      this.robaForm.controls.predmetStavkeDto
        .get('nabavnaCena')
        .updateValueAndValidity();
    }

    if (this._dialogConfig.data && this._dialogConfig.data.jm) {
      this.jmDefaultValue = this._dialogConfig.data.jm;
    }

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.saveRoba();
      })
    );

    this._subs.add(
      this._pdvService.getJeKompanijaUPdv.subscribe((res) => {
        this.showStopaPdv = res;
      })
    );

    this._subs.add(
      this.robaForm.controls.predmetStavkeDto.get('stopaPDV').valueChanges.subscribe((res)=>{
        this.handleProdajnaCenaChanged(res);
        this._setShouldShowRazlogPdvIzuzeca(res);
      })
    )

    this._subs.add(
      this._robaForm.getTipDokumentaSubjectObservable.subscribe(
        (stopa) => {
          this.handleProdajnaCenaChanged(stopa);
        }
      )
    );

  }

  handleProdajnaCenaChanged(stopa: StopaPdv) {
    const prodajnaCenabBezPdv = this.robaForm.controls.predmetStavkeDto.value.prodajnaCenaBezPdv;
    const pdvStopa = stopa != null ? stopa : this.robaForm.controls.predmetStavkeDto.value.stopaPDV;
    const prodajnaCenaSaPdv = round(prodajnaCenabBezPdv + prodajnaCenabBezPdv * (pdvStopa / 100));
    this.robaForm.controls.predmetStavkeDto.patchValue({
      prodajnaCenaSaPdv: prodajnaCenaSaPdv
    });
  }

  handleProdajnaCenaSaPdvChanged(stopa: StopaPdv) {
    const prodajnaCenaSaPdv = this.robaForm.controls.predmetStavkeDto.value.prodajnaCenaSaPdv;
    const pdvStopa = stopa != null ? stopa : this.robaForm.controls.predmetStavkeDto.value.stopaPDV;
    const prodajnaCenaBezPdv = round(prodajnaCenaSaPdv / (1+(pdvStopa/100)));
    this.robaForm.controls.predmetStavkeDto.patchValue({
      prodajnaCenaBezPdv: prodajnaCenaBezPdv
    });
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.saveRoba();
    }
  }

  selectedRepromaterijali(repromaterijali: IRepromaterijalDto[]) {
    (this.robaForm.controls.repromaterijali as FormArray).clear();
    repromaterijali.forEach((element) => {
      (this.robaForm.controls.repromaterijali as FormArray).push(
        this._robaForm.GetRepromaterijaliFormGroup(element)
      );
    });
  }

  selectedMagacini(magacini: IMagacinDto[]) {
    (this.robaForm.controls.magacini as FormArray).clear();
    magacini.forEach((element) => {
      (this.robaForm.controls.magacini as FormArray).push(
        this._magacinFS.GetMagacinFormGroup(element)
      );
    });
  }

  getRoba(id: number) {
    this._subs.add(
      this._client.getById(id).subscribe((model) => {
        this.robaForm.patchValue(model.data);
        this._robaForm.setStopaPdv = this.robaForm.value.predmetStavkeDto.stopaPDV;
        this.selectedRepromaterijali(model.data.repromaterijali);
        this.selectedMagacini(model.data.magacini);
        this._setShouldShowRazlogPdvIzuzeca(model.data?.predmetStavkeDto?.stopaPDV);
      })
    );
  }

  private _setShouldShowRazlogPdvIzuzeca(stopa: StopaPdv) {
    if(stopa == StopaPdv.BEZ_PDV){
      this.shouldShowRazlogPdvIzuzeca = true;
    }
    else{
      this.shouldShowRazlogPdvIzuzeca = false;
      this.robaForm.controls.predmetStavkeDto.get('oznakaPdvKategorije').patchValue(null);
      this.robaForm.controls.predmetStavkeDto.get('razlogPdvIzuzecaId').patchValue(null);
    }
  }

  private saveRoba() {
    if (!this.robaForm.valid) {
      this._formHelper.invalidateForm(this.robaForm);
      return;
    }

    if (this.mode === Mode.EDIT) {
      let command = this.robaForm.value as UpdateRobaCommand;

      //zbog prikaza koristimo only-text-input koji prima stringove - externalId je number tipa i zbog toga moramo da prazan string postavimo na null pre slanja requesta
      if(command.predmetStavkeDto.externalId != null && command.predmetStavkeDto.externalId.toString() === ""){
        command.predmetStavkeDto.externalId = null;
      }

      this._subs.add(
        this._client
          .update(
            this.robaForm.value.predmetStavkeDto.id,
            command
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(
              `Roba ${this.robaForm.value.predmetStavkeDto.naziv} je uspešno izmenjena.`
            );
            this._dialogRef.close(res);
          })
      );
    } else {
      let command = this.robaForm.value as CreateRobaCommand;

      //zbog prikaza koristimo only-text-input koji prima stringove - externalId je number tipa i zbog toga moramo da prazan string postavimo na null pre slanja requesta
      if(command.predmetStavkeDto.externalId != null && command.predmetStavkeDto.externalId.toString() === ""){
        command.predmetStavkeDto.externalId = null;
      }
      this._subs.add(
        this._client
          .create(command)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(
              `Roba ${this.robaForm.value.predmetStavkeDto.naziv} je uspešno kreirana.`
            );
            this._dialogRef.close(res);
          })
      );
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
