import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-maloprodaja-page-izvestaji',
  templateUrl: './maloprodaja-page-izvestaji.component.html',
  styleUrls: ['./maloprodaja-page-izvestaji.component.scss']
})
export class MaloprodajaPageIzvestajiComponent implements OnInit {

  izvestajOptions: SelectItem[] = [];
  public selectedOption: VrstaIzvestajaMaloprodaja = VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ;
  
  constructor() { }

  ngOnInit(): void {
    this._loadDropdownOptions();
  }

  private _loadDropdownOptions() {
    this.izvestajOptions.push({
      value: VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ,
      label: 'Dnevni izveštaj',
    });

    this.izvestajOptions.push({
      value: VrstaIzvestajaMaloprodaja.PERIODICNI_IZVESTAJ,
      label: 'Periodnični izveštaj',
    });
    this.izvestajOptions.push({
      value: VrstaIzvestajaMaloprodaja.PERIODICNI_KASIR,
      label: 'Izveštaj po kasirima',
    });
    this.izvestajOptions.push({
      value: VrstaIzvestajaMaloprodaja.IZVESTAJ_PO_ARTIKLIMA,
      label: 'Izveštaj prodatih artikala',
    });
  }

  optionChanged(event: any) {
    this.selectedOption = event;
  }

}

export enum VrstaIzvestajaMaloprodaja  {
  DNEVNI_IZVESTAJ = 0,
  PERIODICNI_IZVESTAJ = 1,
  PERIODICNI_KASIR = 2,
  IZVESTAJ_PO_ARTIKLIMA = 3
}

