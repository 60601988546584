<form (keydown)="keyDownFunction($event)" [formGroup]="form">
  <div class="p-fluid">
    <!-- Naziv -->
    <kodit-text-input-field
      [formCtrl]="form.controls.naziv"
      formCtrlId="naziv"
      formCtrlLabel="Naziv"
    ></kodit-text-input-field>

    <!-- Tip -->

    <kodit-field fieldLabel="Tip računa">
      <kodit-tip-racuna-dropdown
        [allowedTypes]="tipoviRacuna"
        [isDisabled]="shouldInputBeDisabled"
        [tipCtrl]="form.controls.tipRacuna"
      >
      </kodit-tip-racuna-dropdown>
    </kodit-field>
    <div class="p-grid">
      <kodit-field fieldLabel="Prefiks" class="p-col-4">
        <kodit-text-input
          [formCtrl]="form.controls.prefiks"
          [isDisabled]="shouldInputBeDisabled"
          formCtrlId="prefiks"
        >
        </kodit-text-input>
      </kodit-field>

      <kodit-field fieldLabel="Brojač" class="p-col-4">
        <kodit-number-input
          [ctrlOnlyIntegers]="true"
          [formCtrl]="form.controls.brojac"
          [isDisabled]="shouldInputBeDisabled"
          formCtrlId="brojac"
        >
        </kodit-number-input>
      </kodit-field>

      <span style="font-size: 1.3rem" class="p-col-4 p-mt-5">
        <strong>/ {{ currentYear }}</strong>
      </span>
    </div>
  </div>

  <hr />

  <kodit-checkbox
    [formCtrl]="form.controls.isDefault"
    labelText="Postaviti kao osnovno pravilo"
  ></kodit-checkbox>
  <kodit-minimax-pravila-za-broj-dropdown *ngIf="isMinimaxInitialized" [formCtrl]="form.controls.minimaxId"></kodit-minimax-pravila-za-broj-dropdown>
</form>
