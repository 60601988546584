<kodit-paket-info class="kodit-paket-info"></kodit-paket-info>

<div class="p-grid p-mt-5">
  <!-- <kodit-podesavanja-card
    class="p-col-12 p-md-6 p-lg-4"
    cardTitle="Generalno"
    cardSubtitle="Opcije koje se tiču svih aspekata aplikacije"
    cardIcon="fas fa-cog"
    cardLink="generalno"
  >
  </kodit-podesavanja-card> -->

  <!-- Kompanija -->
  <kodit-podesavanja-card
    cardIcon="far fa-building"
    cardLink="kompanija"
    cardSubtitle="Pregled i podešavanje osnovnih podataka kao i bankovnih naloga kompanije"
    cardTitle="Kompanija"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Racun -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayRacunCard"
    cardIcon="fas fa-file-invoice"
    cardLink="racun"
    cardSubtitle="Pregled i podešavanje podataka vezanih za račune"
    cardTitle="Račun"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Korisnicki nalozi -->
  <kodit-podesavanja-card

    cardIcon="fas fa-users-cog"
    cardLink="korisnicki-nalozi"
    cardSubtitle="Pregled i podešavanje korisničkih naloga, koriskičkih grupa i njihovih ovlašćenja"
    cardTitle="Korisnički nalozi"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Profil -->
  <!-- <kodit-podesavanja-card
    class="p-col-12 p-md-6 p-lg-4"
    cardTitle="Moj profil"
    cardSubtitle="Pregled i podešavanje profila korisnika"
    cardIcon="far fa-address-card"
    cardLink="moj-profil"
  >
  </kodit-podesavanja-card> -->

  <!-- Liste -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayListeCard"
    cardIcon="fas fa-list"
    cardLink="liste"
    cardSubtitle="Opcije koje se tiču svih listi u aplikaciji"
    cardTitle="Šifarnici"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Oznake -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayOznakeCard"
    cardIcon="fas fa-tags"
    cardLink="oznake"
    cardSubtitle="Prikaz svih oznaka u aplikaciji"
    cardTitle="Oznake"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Pocetna stanja -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayPocetnaStanjaCard"
    cardIcon="fas fa-file-import"
    cardLink="pocetna-stanja"
    cardSubtitle="Podešavanje početnih stanja kupaca, dobavljača, artikala..."
    cardTitle="Početna stanja i šifarnici"
    class="p-col-12 p-md-6 p-lg-4"
  >
  </kodit-podesavanja-card>

  <!-- Fiskalni racun -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayFiskalnaCard"
    class="p-col-12 p-md-6 p-lg-4"
    cardTitle="FIskalna kasa"
    cardSubtitle="Podešavanje fiskalne kase"
    cardIcon="fa-solid fa-cash-register"
    cardLink="fiskalna-kasa"
  >
  </kodit-podesavanja-card>

  <!-- Arhivska knjiga -->
  <kodit-podesavanja-card
    *ngIf="shouldDisplayArhivskaCard"
    class="p-col-12 p-md-6 p-lg-4"
    cardTitle="Arhivska knjiga"
    cardSubtitle="Inventarski pregeled dokumentarkog materijala"
    cardIcon="fas fa-light fa-folder-tree"
    cardLink="arhivska-knjiga"
  ></kodit-podesavanja-card>

  <!-- API menadzment-->
  <kodit-podesavanja-card
    class="p-col-12 p-md-6 p-lg-4"
    cardTitle="API menadžment"
    cardSubtitle="Podešavanje API ključa za eKompaniju"
    cardIcon="fas fa-webhook"
    cardLink="api-menadzment"
  ></kodit-podesavanja-card>
</div>
