import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { MinimaxClient } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-organisation-dropdown',
  templateUrl: './organisation-dropdown.component.html',
  styleUrls: ['./organisation-dropdown.component.scss'],
})
export class OrganisationDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _client: MinimaxClient) {}

  ngOnInit(): void {
    this._load();
  }

  handleItemChange(event: any){
    this.onItemChange.emit(event);
  }

  private _load() {
    this._subs.add(
      this._client.getOrganisations()
        .subscribe(res => {
          if(res.succeeded){
            this.items = res.data.map((x) => ({
              value: x.id,
              label: x.name,
              title: x.name,
            }));
            this.onItemChange.emit(this.items[0]);
          }
        })
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
