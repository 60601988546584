import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogService, ListConfig } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  IListItemDtoOfLong,
  InfoZgradaClient
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ActionType, ListAction } from '../../../../../../shared-ui/src/lib/list/list.config';
import { StanFormComponent } from '../stan-form/stan-form.component';

@Component({
  selector: 'kodit-stan-list',
  templateUrl: './stan-list.component.html',
  styleUrls: ['./stan-list.component.scss'],
})
export class StanListComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  listConfig: ListConfig = new ListConfig();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL
  );

  /** Props */
  stanList: IListItemDtoOfLong[] = [];

  /** I/O */
  @Input() strankaId: number;

  constructor(
    private _dialogService: DynamicDialogService,
    private _route: ActivatedRoute,
    private _client: InfoZgradaClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });
    this.setListConfig();
    this._load();
  }

  async setListConfig(): Promise<void> {
    this.listConfig = {
      title: 'Stanovi',
      subtitle: 'Stanovi vezani za stanara',
      deleteMessage: 'Da li ste...',
      actions: this._getActions(),
      createCallbackFunction: () => this._onCreate(),
    };
  }

  private _load() {
    this._subs.add(
      this._client.getStanList(this.strankaId).subscribe((res) => {
        this.stanList = res.data;
      })
    );
  }

  private _getActions(): ListAction[] {
    return [
      new ListAction({
        type: ActionType.EDIT,
        config: {isAvailable: true},
        callback: (index: number) => {
          this._onEdit(index);
        },
      }),
      new ListAction({
        type: ActionType.ARCHIVE,
        config: {isAvailable: true},
        callback: (index: number) => {
          this._confirmationService.confirm({
            header: 'Potvrda brisanja',
            message: 'Da li želite da obrišete stan?',
            acceptLabel: 'Obriši',
            rejectLabel: 'Odustani',
            rejectButtonStyleClass: 'p-button-outlined',
            accept: () => this._onDelete(index),
          });
        },
      }),
    ];
  }

  private _onCreate(): void {
    this.dialogConfig.data = { id: null, strankaId: this.strankaId };
    this.dialogConfig.header = 'Kreiranje novog stana';
    this.dialogConfig.maximisable = false;
    this.openDialog();
  }

  private _onEdit(index: number): void {
    this.dialogConfig.data = { id: this.stanList[index].id, strankaId: this.strankaId };
    this.dialogConfig.header = 'Ažuriranje stana';
    this.dialogConfig.maximisable = false;
    this.openDialog();
  }

  private _onDelete(index: number): void {
   this._subs.add(
     this._client.deleteStan(this.stanList[index].id)
       .subscribe(res => {
         if(res.succeeded){
           this._alertService.addSuccessMsg(res.data);
           this._load();
           return;
         }
         this._alertService.addWarnMsg(res.messages[0]);
       })
   )
  }

  openDialog(): void {
    const ref = this._dialogService.open(StanFormComponent, this.dialogConfig);

    this._subs.add(
      ref.onClose.subscribe(value => {
        if(value === true){
          this._load();
        }
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
