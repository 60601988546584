import { CanActivate, Routes } from '@angular/router';
import { DashboardComponent } from '../../../../libs/core/core-ui/src/lib/dashboard/dashboard.component';
import { PackageType, TipPristupa } from '@kodit/core/data-api';
import {
  PromenaFirmeLoaderComponent
} from '../../../../libs/core/core-ui/src/lib/promena-firme-loader/promena-firme-loader.component';
import { CoreModuliBankaModule } from '@kodit/core/moduli/banka';
import { CoreModuliStrankaModule } from '@kodit/core/moduli/stranka';
import { CoreModuliPodesavanjeModule } from '@kodit/core/moduli/podesavanje';
import { CoreModuliIzvestajModule } from '@kodit/core/moduli/izvestaj';
import { CoreModuliFileManagerModule } from '@kodit/core/moduli/file-manager';
import { CoreModuliArhivskaKnjigaModule } from '@kodit/core/moduli/arhivska-knjiga';
import { CoreModuliDelovodnikModule } from '@kodit/core/moduli/delovodnik';
import { CoreModuliRacunModule } from '@kodit/core/moduli/racun';
import { CoreModuliPdvEvidencijaModule } from '@kodit/core/moduli/pdv-evidencija';
import { CoreModuliNotifikacijeModule } from '@kodit/core/moduli/notifikacije';
import { Type } from '@angular/core';

export function getChildrenRoutes(guard: Type<CanActivate>): Routes{
  return  [
    {
      path: 'dashboard',
      component: DashboardComponent,
      data: {
        packageName: [PackageType[PackageType.FAKTURISANJE]],
        breadcrumb: {
          label: 'Početna',
          info: 'home',
          routeInterceptor: (routeLink: any) => {
            return routeLink;
          },
        },
      },
    },
    {
      path: 'promena-firme',
      component: PromenaFirmeLoaderComponent,
      data: {
        breadcrumb: 'Promena firme',
      },
    },
    {
      path: 'banka',
      loadChildren: () => CoreModuliBankaModule,
      canActivate: [guard],
      data: {
        claimName: [TipPristupa[TipPristupa.IZVOD_CRUD]],
        packageName: [PackageType[PackageType.FINANSIJE]],
        breadcrumb: { label: 'Banka', disable: true },
      },
    },
    {
      path: 'maloprodaja',
      canActivate: [guard],
      data: {
        breadcrumb: { label: 'Maloprodaja', disable: true },
      },
      loadChildren: () =>
        import(
          'libs/core/moduli/maloprodaja/src/lib/core-moduli-maloprodaja.module'
          ).then((m) => m.CoreModuliMaloprodajaModule),
    },
    {
      path: 'stranke',
      loadChildren: () => CoreModuliStrankaModule,
      canActivate: [guard],
      data: {
        packageName: [
          PackageType[PackageType.MALOPRODAJA],
          PackageType[PackageType.FAKTURISANJE],
        ],
        breadcrumb: { label: 'Stranke', disable: true },
      },
    },
    {
      path: 'podesavanja',
      loadChildren: () => CoreModuliPodesavanjeModule,
      canActivate: [guard],
      data: {
        breadcrumb: 'Podešavanja',
      },
    },
    {
      path: 'kompanija',
      canActivate: [guard],
      data: {
        packageName: [
          PackageType[PackageType.MALOPRODAJA],
          PackageType[PackageType.FAKTURISANJE],
        ],
        breadcrumb: { label: 'Kompanija', disable: true },
      },
      children: [
        {
          path: 'pravna-lica',
          data: {
            breadcrumb: 'Pravna lica',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/stranka/src/lib/core-moduli-stranka.module'
              ).then((m) => m.CoreModuliStrankaModule),
        },
        {
          path: 'fizicka-lica',
          data: {
            breadcrumb: 'Fizička lica',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/stranka/src/lib/core-moduli-stranka.module'
              ).then((m) => m.CoreModuliStrankaModule),
        },
        {
          path: 'roba',
          data: {
            breadcrumb: 'Roba',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/roba/src/lib/core-moduli-roba.module'
              ).then((m) => m.CoreModuliRobaModule),
          //resolve: { robe: RobeResolver },
        },
        {
          path: 'usluge',
          data: {
            breadcrumb: 'Usluge',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/usluga/src/lib/core-moduli-usluga.module'
              ).then((m) => m.CoreModuliUslugaModule),
        },
        {
          path: 'vozila',
          data: {
            breadcrumb: 'Vozila',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/vozilo/src/lib/core-moduli-vozilo.module'
              ).then((m) => m.CoreModuliVoziloModule),
        },
        {
          path: 'cenovnici',
          data: {
            breadcrumb: 'Cenovnici',
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/cenovnik/src/lib/core-moduli-cenovnik.module'
              ).then((m) => m.CoreModuliCenovnikModule),
        },
        {
          path: 'izvestaji',
          data: {
            breadcrumb: 'Izveštaji',
          },
          loadChildren: () => CoreModuliIzvestajModule,
        },
        {
          path: 'file-manager',
          data: {
            breadcrumb: 'Fajl menadžer',
          },
          loadChildren: () => CoreModuliFileManagerModule,
        },
        {
          path: 'arhivska-knjiga',
          data: {
            breadcrumb: 'Arhivska knjiga',
          },
          loadChildren: () => CoreModuliArhivskaKnjigaModule,
        },
        {
          path: 'delovodnik',
          canActivate: [guard],
          data: {
            breadcrumb: 'Delovodnik',
          },
          loadChildren: () => CoreModuliDelovodnikModule,
        },
      ],
    },
    {
      path: 'email',
      canActivate: [guard],
      data: {
        breadcrumb: { label: 'Email', disable: true },
      },
      loadChildren: () =>
        import(
          'libs/core/moduli/email/src/lib/core-moduli-email.module'
          ).then((m) => m.CoreModuliEmailModule),
    },
    // robno
    {
      path: 'robno',
      canActivate: [guard],
      data: {
        breadcrumb: { label: 'Robno', disable: true },
        packageName: [PackageType[PackageType.VELEPRODAJA]],
      },
      children: [
        {
          path: 'magacini',
          data: {
            breadcrumb: { label: 'Magacini' },
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/magacin/src/lib/core-moduli-magacin.module'
              ).then((m) => m.CoreModuliMagacinModule),
        },
        {
          path: 'lageri',
          data: {
            breadcrumb: { label: 'lageri' },
          },
          loadChildren: () =>
            import(
              'libs/core/moduli/roba/src/lib/core-moduli-roba.module'
              ).then((m) => m.CoreModuliRobaModule),
        },
        {
          path: 'kalkulacije',
          data: {
            // calimName:
            breadcrumb: { label: 'Kalkulacije' },
          },
          loadChildren: () =>
            import('libs/core/moduli/kalkulacije/kalkulacije.module').then(
              (m) => m.KalkulacijeModule
            ),
        },
      ],
    },
    {
      path: 'racuni',
      loadChildren: () => CoreModuliRacunModule,
      canActivate: [guard],
      data: {
        breadcrumb: { disable: true, label: 'Računi' },
      },
    },
    {
      path: 'dokumenti',
      loadChildren: () => CoreModuliPdvEvidencijaModule,
      canActivate: [guard],
      data: {
        breadcrumb: { disable: true, label: 'Dokumenti' },
      },
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'dashboard',
    },
    {
      path: 'notifikacije',
      data: {
        breadcrumb: 'Obaveštenja',
      },
      loadChildren: () => CoreModuliNotifikacijeModule,
    },
  ];
}
