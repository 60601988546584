import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KlasifikacijaRacuna, UlazneFaktureClient, UpdateUlaznaFakturaCommand } from '@kodit/core/data-api';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-klasifikacija-dokumenta-form',
  templateUrl: './klasifikacija-dokumenta-form.component.html',
  styleUrls: ['./klasifikacija-dokumenta-form.component.scss']
})
export class KlasifikacijaDokumentaFormComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();
  form: FormGroup;
  racunId: number;

  constructor(
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilder,
    private _dialogConfig: DynamicDialogConfig,
    private _sharedService : SharedService,
    private _client: UlazneFaktureClient,
    private _alertService: AlertService,
    private _buttonService: ButtonService,
  ) { }

  ngOnInit(): void {

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._klasifikujDokument();
      })
    );

    this.form = this._fb.group({
      klasifikacijaRacuna: new FormControl(this._dialogConfig.data.racun.klasifikacijaRacuna),
      pravoNaPdvOdbitak: new FormControl(this._dialogConfig.data.racun.pravoNaPdvOdbitak)
    });
    this.racunId = this._dialogConfig.data.racun.id;
  }

  private _klasifikujDokument(){

    if(this.form.value.klasifikacijaRacuna == undefined && this.form.value.pravoNaPdvOdbitak == undefined){
      this._alertService.addWarnMsg("Ukoliko želite da klasifikujete dokument izaberite ponuđene tipove");
      return;
    }

    this._buttonService.disableButton();

    this._sharedService.displayLoadingScreen(true,"Klasifikacija računa u toku");
    this._subs.add(
      this._client
        .klasifikacija(
            this.racunId,
            this.form.value.klasifikacijaRacuna,
            this.form.value.pravoNaPdvOdbitak
        )
        .subscribe((res) => {
          if(res.succeeded){
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(true);
            return;
          }
          this._alertService.addFailedMsg(res.messages[0]);
          this._dialogRef.close(true);
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
