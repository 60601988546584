import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@kodit/core/services';
import { InfoZgradaGuard } from '@kodit/core/services';
import { CoreAuthModule } from '@kodit/core/auth';
import { AppLayoutComponent } from 'libs/core/core-ui/src/lib/layouts/app-layout/app-layout.component';
import { LoginLayoutComponent } from 'libs/core/core-ui/src/lib/layouts/login-layout/login-layout.component';
import { getChildrenRoutes } from './app-children-routes';


const routes: Routes = [
  //Default
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: getChildrenRoutes(AuthGuard)
  },
  // //InfoZgrada
  // {
  //   path: 'info-zgrada',
  //   component: AppLayoutComponent,
  //   canActivate: [InfoZgradaGuard],
  //   data: {
  //     isInfoZgrada: true
  //   },
  //   children: getChildrenRoutes(InfoZgradaGuard)
  // },
  //Login
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'autentikacija',
        loadChildren: () => CoreAuthModule,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/autentikacija',
      },
    ],
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      // preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
