import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService, ButtonService, Mode, SharedService } from '@kodit/core/services';
import {
  CreateFizickoLiceCommand,
  FizickaLicaClient,
  IFizickoLiceDto,
  PolFizickogLica,
  UpdateFizickoLiceCommand,
} from '@kodit/core/data-api';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  FizickoLiceFormService,
  FormHelper,
} from 'libs/core/form-definitions/src';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-fizicko-lice-form',
  templateUrl: './fizicko-lice-form.component.html',
  styleUrls: ['./fizicko-lice-form.component.scss'],
})
export class FizickoLiceFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  fizickoLiceForm: FormGroupTypeSafe<IFizickoLiceDto>;
  mode: Mode = Mode.NEW;
  activeIndex: number = 0;
  polItems: SelectItem[] = [];

  constructor(
    private _client: FizickaLicaClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _formService: FizickoLiceFormService,
    private _FormHelper: FormHelper,
    private _buttonService: ButtonService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    /** init form */
    this.fizickoLiceForm = this._formService.GetFizickoLiceFormGroup();
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.saveFizickoLice();
      })
    );
    /** get mode and patch if needed */
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getFizickoLice(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }
    if (this._dialogConfig.data && this._dialogConfig.data.ime) {
      this.fizickoLiceForm.controls.ime.patchValue(this._dialogConfig.data.ime);
      this.fizickoLiceForm.controls.prezime.patchValue(
        this._dialogConfig.data.prezime
      );
      this.fizickoLiceForm.controls.adresa
        .get('grad')
        .patchValue(this._dialogConfig.data.grad);
    }
    this.polItems = [
      {
        label: 'Izaberite pol',
        title: 'Izaberite pol',
        value: PolFizickogLica.EMPTY,
      },
      { label: 'Žensko', title: 'Žensko', value: PolFizickogLica.ZENSKO },
      { label: 'Muško', title: 'Muško', value: PolFizickogLica.MUSKO },
      { label: 'Drugo', title: 'Drugo', value: PolFizickogLica.DRUGO },
    ];
  }

  saveFizickoLice() {
    if (!this.fizickoLiceForm.valid) {
      /** Invalid form */
      this._FormHelper.invalidateForm(this.fizickoLiceForm);
      return;
    }

    // everything is ok, let's save it
    if (this.mode === Mode.EDIT) {

      this._sharedService.displayLoadingScreen(true, 'Ažuriranje fizičkog lica...');
      /** Edit */
      this._subs.add(
        this._client
          .update(
            this.fizickoLiceForm.value.id,
            this.fizickoLiceForm.value as UpdateFizickoLiceCommand
          )
          .subscribe(
            (result) => {
              this._alertService.addSuccessMsg(
                `Fizičko lice ${this.fizickoLiceForm.value.ime} ${this.fizickoLiceForm.value.prezime}je uspešno izmenjeno.`
              );
              this._dialogRef.close(result);
            },
            (error) => {
              this._subs.add(
                error.subscribe((x: string) => {
                  this._alertService.addFailedMsg(x);
                })
              );
            }
          )
      );
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje fizičkog lica...');
      /** Create */
      this._subs.add(
        this._client
          .create(this.fizickoLiceForm.value as CreateFizickoLiceCommand)
          .subscribe(
            (result) => {
              this._alertService.addSuccessMsg(
                `Fizičko lice ${this.fizickoLiceForm.value.ime} ${this.fizickoLiceForm.value.prezime} kreirano.`
              );
              this._dialogRef.close(result);
            },
            (error) => {
              this._subs.add(
                error.subscribe((x: string) => {
                  this._alertService.addFailedMsg(x);
                })
              );
            }
          )
      );
    }
  }

  private getFizickoLice(id: number) {
    this._subs.add(
      this._client.getById(id).subscribe(
        (model) => {
          if (model.data.datumRodjenja != undefined) {
            model.data.datumRodjenja = new Date(model.data.datumRodjenja);
          }
          this.fizickoLiceForm.patchValue(model.data);
          this.fizickoLiceForm.updateValueAndValidity();
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.saveFizickoLice();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
