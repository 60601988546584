import { Injectable } from '@angular/core';
import {
  IDokumentForPdvDto,
  IPdvEvidencijaPojedinacna39Dto,
  IPdvPrometObject,
  IStrankaForPdvDto,
  PdvEvidencijaOsnovOdabiraDokumenta,
  PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijaTipPovezaneFakture,
  PdvEvidencijaTipPovezanogInternog,
  PdvPeriod,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  AbstractControl,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  Pdv39Form,
  PdvEvidencijaPojedinacnaService,
} from './pdv-evidencija-pojedinacna.service';
import { VezaniRacunFormService } from '@kodit/core/form-definitions';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaPojedinacnaForm39Service {
  /* Props */
  private _form!: Pdv39Form;
  private _isInitialized: boolean;

  private osnovicaGroups: OsnovicaGroup[] = [];

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaPojedinacnaService,
    private _vezaniRacunFS: VezaniRacunFormService
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPdvEvidencijaPojedinacna39Dto,
    shouldDisableControls: boolean
  ): Pdv39Form {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    this._service.setPdv39Form = this._form;
    return this._form;
  }

  initOsnovicaGroups() {
    this.osnovicaGroups = [];
    this.osnovicaGroups.push(
      // Promet 10% osim OBJ
      {
        opis: this._form!.controls!.promet10!.get('opis')!,
        osnovica: this._form!.controls!.promet10!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet10!.get('obracunatiPdv')!,
        isObj: false,
      },
      // Promet 20% osim OBJ
      {
        opis: this._form!.controls!.promet20!.get('opis')!,
        osnovica: this._form!.controls!.promet20!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet20!.get('obracunatiPdv')!,
        isObj: false,
      },
      // Promet 10% OBJ
      {
        opis: this._form!.controls!.promet10Obj!.get('opis')!,
        osnovica: this._form!.controls!.promet10Obj!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet10Obj!.get('obracunatiPdv')!,
        isObj: true,
      },
      // Promet 20% OBJ
      {
        opis: this._form!.controls!.promet20Obj!.get('opis')!,
        osnovica: this._form!.controls!.promet20Obj!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet20Obj!.get('obracunatiPdv')!,
        isObj: true,
      },
      // Promet 10% bez naknade, osim OBJ
      {
        opis: this._form!.controls!.promet10Bn!.get('opis')!,
        osnovica: this._form!.controls!.promet10Bn!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet10Bn!.get('obracunatiPdv')!,
        isObj: false,
      },
      // Promet 20% bez naknade, osim OBJ
      {
        opis: this._form!.controls!.promet20Bn!.get('opis')!,
        osnovica: this._form!.controls!.promet20Bn!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet20Bn!.get('obracunatiPdv')!,
        isObj: false,
      },
      // Promet 10% bez naknade OBJ
      {
        opis: this._form!.controls!.promet10BnObj!.get('opis')!,
        osnovica: this._form!.controls!.promet10BnObj!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet10BnObj!.get(
          'obracunatiPdv'
        )!,
        isObj: true,
      },
      // Promet 20% bez naknade OBJ
      {
        opis: this._form!.controls!.promet20BnObj!.get('opis')!,
        osnovica: this._form!.controls!.promet20BnObj!.get('osnovica')!,
        obracunatiPdv: this._form!.controls!.promet20BnObj!.get(
          'obracunatiPdv'
        )!,
        isObj: true,
      }
    );
  }

  hasObjAndNotObjProps() {
    return (
      this.osnovicaGroups.some(
        (x) =>
          (x.opis.value || x.osnovica.value || x.obracunatiPdv.value) && x.isObj
      ) &&
      this.osnovicaGroups.some(
        (x) =>
          (x.opis.value || x.osnovica.value || x.obracunatiPdv.value) && !x.isObj
      )
    );
  }

  areOsnovicaGroupsValid(): boolean {
    return this.osnovicaGroups.some(
      (x) => x.opis.value && x.osnovica.value && x.obracunatiPdv.value
    );
  }

  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  private _init(
    model: IPdvEvidencijaPojedinacna39Dto,
    shouldDisableComponents: boolean
  ) {
    this._form = this._fb.group<IPdvEvidencijaPojedinacna39Dto>({
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required, Validators.maxLength(32)],
        }
      ),
      tip: new FormControl(model?.tip ?? PdvEvidencijaTipDokumenta.FAKTURA),
      osnovOdabiraDokumenta: new FormControl(
        model?.osnovOdabiraDokumenta ??
          PdvEvidencijaOsnovOdabiraDokumenta.PROMET
      ),
      tipPovezanogInternog: new FormControl(
        model?.tipPovezanogInternog ??
          PdvEvidencijaTipPovezanogInternog.INTERNI_RACUN_ZA_PROMET
      ),
      tipPovezaneFakture: new FormControl(
        model?.tipPovezaneFakture ?? PdvEvidencijaTipPovezaneFakture.FAKTURA
      ),
      datumEvidentiranja: new FormControl(
        model?.datumEvidentiranja ?? new Date()
      ),
      vremenskiPeriodPocetak: new FormControl(model?.vremenskiPeriodPocetak),
      vremenskiPeriodZavrsetak: new FormControl(
        model?.vremenskiPeriodZavrsetak
      ),
      povezanaFaktura:
        !model || model.smer === VrstaRacuna.IZLAZNI
          ? this._getDokumentFormGroup(model?.povezanaFaktura)
          : undefined,
      brojDokumentaNabavka:
        model && model.smer === VrstaRacuna.ULAZNI
          ? new FormControl(model?.brojDokumentaNabavka, {
              validators: [Validators.maxLength(40), Validators.required],
            })
          : undefined,
      obracunatPdv: new FormControl(model?.obracunatPdv),
      promet10: this._getPrometObjFormGroup(model?.promet10),
      promet20: this._getPrometObjFormGroup(model?.promet20),
      promet10Obj: this._getPrometObjFormGroup(model?.promet10Obj),
      promet20Obj: this._getPrometObjFormGroup(model?.promet20Obj),
      promet10Bn: this._getPrometObjFormGroup(model?.promet10Bn),
      promet20Bn: this._getPrometObjFormGroup(model?.promet20Bn),
      promet10BnObj: this._getPrometObjFormGroup(model?.promet10BnObj),
      promet20BnObj: this._getPrometObjFormGroup(model?.promet20BnObj),

      id: new FormControl(model?.id),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      smer: new FormControl(model?.smer ?? VrstaRacuna.IZLAZNI),
      datumAvansa: new FormControl(model?.datumAvansa),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),
      stranka: this._getStrankaFormGroup(model?.stranka),
      osnovAvansnogPlacanja: new FormControl(model?.osnovAvansnogPlacanja, {
        validators: [Validators.maxLength(200)],
      }),
      ukupanIznosNaknade: new FormControl(model?.ukupanIznosNaknade),
      povezaniRacuni: this._vezaniRacunFS.getFormArray(model?.povezaniRacuni),
      povezaniVanSefa: this.getAvansniVanSefaFormArray(model?.povezaniVanSefa),
      sefId: new FormControl(model?.sefId ?? 0),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
    });

    this.initOsnovicaGroups();

    this._form.controls.stranka!.get('pibOrJbkjs')!.disable();

    this._service.setIsIsporuka =
      this._form.value.povezanaFaktura?.smer === VrstaRacuna.IZLAZNI;
    this._service.setTipDokumentaSubject = this._form.value.tip!;
    this._service.setOsnovOdabira = this._form.value.osnovOdabiraDokumenta!;
  }

  private _getStrankaFormGroup(model?: IStrankaForPdvDto) {
    return this._fb.group<IStrankaForPdvDto>({
      naziv: new FormControl(model?.naziv),
      id: new FormControl(model?.id),
      pibOrJbkjs: new FormControl(model?.pibOrJbkjs, {}),
    });
  }

  private _getPrometObjFormGroup(model?: IPdvPrometObject) {
    return this._fb.group<IPdvPrometObject>({
      opis: new FormControl(model?.opis),
      osnovica: new FormControl(model?.osnovica),
      obracunatiPdv: new FormControl(model?.obracunatiPdv),
    });
  }

  private _getDokumentFormGroup(model?: IDokumentForPdvDto) {
    return this._fb.group<IDokumentForPdvDto>({
      id: new FormControl(model?.id),
      broj: new FormControl(model?.broj),
      smer: new FormControl(model?.smer ?? VrstaRacuna.IZLAZNI),
      stranka: this._getStrankaFormGroup(model?.stranka),
      tip: new FormControl(model?.tip),
      jeInostranaFaktura: new FormControl(model?.jeInostranaFaktura ?? false),
    });
  }

  getAvansniVanSefaFormArray(racuni?: string[]): FormArray {
    if (!racuni || racuni.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    racuni.forEach((s) => {
      result.push(new FormControl(s));
    });
    return result;
  }

  resetOnIsporukaNabavkaChange(isIsporuka: boolean) {
    this.resetDokumentAndStranka();
    this.resetGlavniDokumentForm(isIsporuka);
    this.resetAllProps();
  }

  resetDokumentAndStranka() {
    this._form?.controls.povezanaFaktura?.reset();
    this._form?.controls.stranka?.reset();
    this._service.setDokumentBrojChanged = '';
  }

  resetDatumAvansa() {
    this._form?.controls.datumAvansa?.reset();
  }

  resetVezaneRacune() {
    (this._form?.controls.povezaniRacuni as FormArray).clear();
    (this._form?.controls.povezaniVanSefa as FormArray).clear();
  }

  resetForm() {
    this._service.resetForm39();
    this._isInitialized = false;
    this._init({}, false);
  }

  private resetGlavniDokumentForm(isIsporuka: boolean) {
    if (isIsporuka) {
      this._form!.setControl('povezanaFaktura', this._getDokumentFormGroup());
      this._form?.setControl('brojDokumentaNabavka', undefined as any);
      return;
    }

    this._form?.setControl(
      'brojDokumentaNabavka',
      new FormControl(null, {
        validators: [Validators.maxLength(40), Validators.required],
      })
    );
    this._form?.setControl('povezanaFaktura', undefined as any);
  }

  resetAllProps() {
    this.resetNonBnProps();
    this.resetBNProps();
  }

  resetOsnovniPodaci() {
    this._form?.controls.broj?.reset();
    this._form?.controls.godina?.reset();
    this._form?.controls.period?.reset();
  }

  resetNonBnProps() {
    // 10%, osim obj
    this._form?.controls.promet10!.get('opis')?.patchValue('');
    this._form?.controls.promet10!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet10!.get('obracunatiPdv')?.patchValue(null);

    // 20% osim obj
    this._form?.controls.promet20!.get('opis')?.patchValue('');
    this._form?.controls.promet20!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet20!.get('obracunatiPdv')?.patchValue(null);

    // 10% obj
    this._form?.controls.promet10Obj!.get('opis')?.patchValue('');
    this._form?.controls.promet10Obj!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet10Obj!.get('obracunatiPdv')?.patchValue(null);

    // 20% obj
    this._form?.controls.promet20Obj!.get('opis')?.patchValue('');
    this._form?.controls.promet20Obj!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet20Obj!.get('obracunatiPdv')?.patchValue(null);
  }

  resetBNProps() {
    // 10% bez naknade, osim obj
    this._form?.controls.promet10Bn!.get('opis')?.patchValue('');
    this._form?.controls.promet10Bn!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet10Bn!.get('obracunatiPdv')?.patchValue(null);

    // 20% bez naknade, osim obj
    this._form?.controls.promet20Bn!.get('opis')?.patchValue('');
    this._form?.controls.promet20Bn!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet20Bn!.get('obracunatiPdv')?.patchValue(null);

    // 10% bez naknade obj
    this._form?.controls.promet10BnObj!.get('opis')?.patchValue('');
    this._form?.controls.promet10BnObj!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet10BnObj!.get('obracunatiPdv')?.patchValue(null);

    // 20% bez naknade obj
    this._form?.controls.promet20BnObj!.get('opis')?.patchValue('');
    this._form?.controls.promet20BnObj!.get('osnovica')?.patchValue(null);
    this._form?.controls.promet20BnObj!.get('obracunatiPdv')?.patchValue(null);
  }
}

export interface OsnovicaGroup {
  opis: AbstractControl;
  osnovica: AbstractControl;
  obracunatiPdv: AbstractControl;
  isObj: boolean;
}
