import { Injectable } from "@angular/core";
import { IDnevniIzvestajMaloprodajaFilter, MaloprodajaIzvestajDto } from "@kodit/core/data-api";
import { FormGroupTypeSafe } from "angular-typesafe-reactive-forms-helper";
import { BehaviorSubject, Observable } from "rxjs";
import { VrstaIzvestajaMaloprodaja } from "./maloprodaja/maloprodaja-page/maloprodaja-page-izvestaji.component";

@Injectable({
  providedIn: 'root',
})

export class MaloprodajaIzvestajService {

  tableData$ = new BehaviorSubject<any>(null);
  filter$ = new BehaviorSubject<FilterForm>(null);
  tip$ = new BehaviorSubject<VrstaIzvestajaMaloprodaja>(VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ);

  set setTableData(tableData: any){
    this.tableData$.next(tableData);
  }

  get getTableData(): boolean {
  return this.tableData$.getValue();
  }

  get getTableDataObservable(): Observable<any> {
    return this.tableData$.asObservable();
  }

  set setFilter(model: FilterForm) {
    this.filter$.next(model);
  }

  get getFilterObject(): FilterForm {
    return this.filter$.getValue();
  }

  get getFilterObservable(): Observable<FilterForm> {
    return this.filter$.asObservable();
  }

  set setTip(tip: any){
    this.tip$.next(tip);
  }

  get getTip(): VrstaIzvestajaMaloprodaja {
    return this.tip$.getValue();
  }

  get getTipObservable(): Observable<VrstaIzvestajaMaloprodaja> {
    return this.tip$.asObservable();
  }

}
export declare type FilterForm = FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter> | null;
