import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-error-info-jmbg',
  templateUrl: './error-info-jmbg.component.html',
  styleUrls: ['./error-info-jmbg.component.scss']
})
export class ErrorInfoJmbgComponent implements OnInit {

  /** Props */
  errorMsg: string = 'JMBG mora imati 12 ili 13 cifara!';

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() { }

  ngOnInit(): void {
  }

}
