<div>
    <kodit-field fieldLabel="Izaberite tip izveštaja: " fieldLabelFor="tip_izvestaja">
        <div style="width: 20%">
            <kodit-dropdown
              formCtrlId="vrsta-pojedinacne-pdv-evidencije-dropdown"
              [options]="izvestajOptions"
              (optionChanged)="optionChanged($event)"
            ></kodit-dropdown>
          </div>
    </kodit-field>
</div>

<kodit-page>
  <kodit-maloprodaja-filter-izvestaji
    [tipIzvestaja] = "selectedOption"
  ></kodit-maloprodaja-filter-izvestaji>
</kodit-page>

<kodit-page>
    <kodit-maloprodaja-table-izvestaji 
    ></kodit-maloprodaja-table-izvestaji>
</kodit-page>


